import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsHorizontalConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { IComponentConfig } from "@/app/@types/base-component";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFTopFrameSortConfig()
        .qty(0)
        .width(qty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig()
        .qty(0)
        .width(qty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig()
        .qty(0)
        .width(qty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            height,
        );

    const qty = width * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLinesQty)
        .width(width)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const verticalLinesQty =
        LineDividerCalculationService.calculateVerticalDividers(
            CGF350Code,
            width,
        );

    const qty = height * verticalLinesQty * sides;

    return createMountsMullionsVerticalConfig()
        .qty(verticalLinesQty)
        .width(height)
        .area(qty)
        .build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const isDoubleDoor = MountsService.isForDoubleDoor(partition);

    const doorSides = isDoubleDoor ? 6 : 4;
    const totalSides = 2;

    const horizontalProfiles = totalHeight * doorSides;
    const verticalProfiles = doorWidth * totalSides;

    const qty = horizontalProfiles + verticalProfiles;

    return createMountsCGFFrameConfig().qty(0).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig()
        .qty(0)
        .width(qty)
        .area(qty)
        .build();
};

export const CGF350SwingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(partition),
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsMullionsVerticalConfig(partition),
    // createSlidingDoorMountsCGFFrameConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
