import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsHorizontalConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { IComponentConfig } from "@/app/@types/base-component";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFTopFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = height * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const qty = width - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const horizontalLineQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const qty = horizontalLineQty * width * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLineQty)
        .width(width)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const partitionWidth = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);

    const verticalLinesQty =
        LineDividerCalculationService.calculateVerticalDividers(
            CGF400Code,
            partitionWidth,
        );
    const sides = 2;

    const qty = verticalLinesQty * height * sides;

    return createMountsMullionsVerticalConfig()
        .qty(verticalLinesQty)
        .width(height)
        .area(qty)
        .build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF400SwingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(partition),
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsMullionsVerticalConfig(partition),
    // createSlidingDoorMountsCGFFrameConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
