import { MountMullionsTopbottom1ValueCode } from "@/app/constants/mounts";
import {
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFFrameConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsMiddleConfig,
    createMountsMullionsTopConfig,
    createMountsToptrackConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsToptrackConfig = (
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(1)
        .build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsTopConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsMiddleConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);

    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            height,
        );

    const sides = 2;

    const qty = width * horizontalLinesQty * sides;

    return createMountsMullionsMiddleConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorsSections = MountsService.isForDoubleDoor(partition) ? 4 : 3;
    const sides = 2;

    const verticalProfiles = totalHeight * sides;

    const qty = verticalProfiles * doorsSections;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const sides = 2;

    const totalGaskets = totalWidth * sides;
    const doorGaskets = doorWidth * sides;

    const qty = totalGaskets - doorGaskets;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF350SlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsToptrackConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsMiddleConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
    createSlidingDoorMountsMullionsTopConfig(partition),
    createSlidingDoorMountsMullionsBottomConfig(partition),
    createSlidingDoorMountsCGFFrameConfig(partition),
];
