import { useDictStore } from "@/stores/dictStore";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import { isKompasSystem } from "@/app/predicates/systems.predicates";
import { MountsToptrackSlidingDoorTypeCode } from "@/app/constants/mounts";

export class MountHardwarePricingStrategy extends PricingStrategy {
    private dictStore = useDictStore();

    public getPrices(...args: unknown[]): PriceResult {
        const [installedSystemCode, hardwareTypeCode, hardwareStructureType] =
            args as string[];

        const multiplicator = isKompasSystem(installedSystemCode) ? 2 : 1;

        const pricesFromDict =
            hardwareTypeCode === MountsToptrackSlidingDoorTypeCode
                ? this.getMountTrackPrice(hardwareStructureType)
                : this.getMountHardwarePrice(
                      hardwareTypeCode,
                      hardwareStructureType,
                  );

        if (!this.validatePricesFromDict(pricesFromDict)) {
            return this.getDefaultPrices();
        }

        return {
            price: pricesFromDict.price! * multiplicator,
            priceB2C: pricesFromDict.priceB2C! * multiplicator,
            priceB2B: pricesFromDict.priceB2B! * multiplicator,
        };
    }

    private getMountHardwarePrice(
        hardwareTypeCode: string,
        hardwareStructureType: string,
    ) {
        return this.dictStore.findMountHardwarePrice(
            hardwareTypeCode,
            hardwareStructureType,
        );
    }

    private getMountTrackPrice(hardwareStructureType: string) {
        const track = this.dictStore.findMullionsTrackPrice(
            hardwareStructureType,
        );

        return {
            ...track,
            price: track?.price || 0,
            priceB2B: track?.price || 0,
            priceB2C: track?.price || 0,
        };
    }
}
