<template>
  <div class="step__block-dimensions">
    <DimensionsTable v-model="activeOverdoor!.dimensions" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";
import { DimensionsTable } from "@/shared";
import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useDoorsStore } from "@/stores/new/doors.store";
import { usePartitionStore } from "@/stores/new/partition.store";

const overdoorStore = useOverdoorStore();
const doorsStore = useDoorsStore();
const partitionsStore = usePartitionStore();

const activePartition = computed(() => partitionsStore.activePartitions);
const activeDoor = computed(() => doorsStore.activeDoor);
const activeOverdoor = computed(() => overdoorStore.activeOverdoor);
const overdoorType = computed(() => activeOverdoor.value?.structureType);

const setupOverdoorDimensions = () => {
  if (!activeOverdoor.value?.dimensions.isAreaEmpty()) return;

  if (activeOverdoor.value?.isNoneOverdoor()) {
    return activeOverdoor.value?.resetDimensions();
  }

  if (activeOverdoor.value?.isFiller()) {
    return activeOverdoor.value?.resetDimensions();
  }

  if (activeOverdoor.value?.isTransom()) {
    return activeOverdoor.value?.setTransomDimensions(
      activePartition.value!,
      activeDoor.value!,
    );
  }
};

watch(
  () => overdoorType,
  () => {
    setupOverdoorDimensions();
  },
);

onMounted(() => {
  setupOverdoorDimensions();
});
</script>

<style lang="scss" scoped></style>
