<template>
  <div class="step__accordion-block">
    <SummaryHeader
      v-if="block.header && block.header"
      :summaryHeader="block.header"
    />
    <ul v-if="lines.length" class="step__accordion-block-list">
      <SummaryLine
        v-for="line in block.lines"
        :key="line.code"
        :summaryLine="line"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { SummaryBlock as TSummaryBlock } from "@/models/summary";

import SummaryLine from "../SummaryLine.vue";
import SummaryHeader from "../SummaryHeader.vue";

type InfoBlockProps = {
  block: TSummaryBlock;
};

const props = defineProps<InfoBlockProps>();
const lines = computed(() => props.block.lines);
</script>

<style scoped></style>
