<template>
  <ul class="step__accordion" :class="className">
    <li class="step__accordion-item" :data-code="objectCode">
      <div class="step__accordion-head" @click="toggleAccordion">
        <div class="step__accordion-overlay"></div>
        <svg
          class="step__accordion-svg"
          :class="{ 'step__accordion-svg--active': isAccordionOpened }"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
        >
          <path
            d="M2 2L8 8L14 2"
            stroke="white"
            stroke-width="2"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
        </svg>
        <slot name="head">
          <h3>{{ title }}</h3>
        </slot>
      </div>
      <div
        class="step__accordion-content"
        :class="{ 'step__accordion-content--active': isAccordionOpened }"
        v-show="isAccordionOpened"
      >
        <slot></slot>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { ref, defineProps } from "vue";

type StepAccordionProps = {
  objectCode: string;
  title: string;
  className?: any;
};

defineProps<StepAccordionProps>();

const isAccordionOpened = ref(false);
const toggleAccordion = () => {
  isAccordionOpened.value = !isAccordionOpened.value;
};
</script>

<style lang="scss" scoped>
.step__accordion-content {
  &--active {
    // padding-bottom: 16px;
  }
}

:deep(.step__accordion-total) {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

:deep(.step__accordion-block) {
  padding-bottom: 22px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

:deep(.step__accordion-block-list) {
  display: grid;
  row-gap: 10px;
}

:deep(.step__accordion-block-dot) {
  width: 2px;
  height: 2px;
  background-color: #fff;
  border-radius: 55px;
  margin-top: 6px;
  /* align-self: center; */
}

:deep(.step__accordion-block-right) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 142px;
  column-gap: 10px;
}

:deep(.step__accordion-block-right div) {
  width: 50%;
  text-align: right;
}

:deep(.step__accordion-block-item) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

:deep(.step__accordion-block-left) {
  display: grid;
  grid-template-columns: minmax(0, max-content) 1fr;
  column-gap: 10px;
}

.step__accordion-block-left h3 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

:deep(.step__accordion-block-container) {
  display: flex;
  align-items: center;
  column-gap: 31px;
}

:deep(.step__accordion-block-units) {
  display: flex;
  align-items: center;
  column-gap: 46px;
}

.step__accordion-svg {
  position: relative;
  transform: rotate(0);
  transition: 0.5s ease;

  &--active {
    transform: rotate(-180deg);
    transition: 0.5s ease;
  }
}

.step__accordion-head {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 15px 0;
  column-gap: 20px;
  /*  */
  cursor: pointer;

  // border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.step__accordion-item {
  &:last-child .step__accordion-head {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.step__accordion-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  cursor: pointer;
}
</style>
