<template>
  <div class="step__left-progressbar">
    <div :style="`width: calc((100% / ${steps.length})*${stepIndex})`"></div>
  </div>
</template>

<script setup lang="ts">
import { useStepsStore } from "@/stores/new/steps.store";
import { computed } from "vue";

const stepsStore = useStepsStore();
const steps = computed(() => stepsStore.steps);
const stepIndex = computed(() => stepsStore.activeStep?.key || 0);
</script>

<style scoped>
.step__left-progressbar {
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  height: 6px;
  margin-bottom: 20px;
}

.step__left-progressbar div {
  border-radius: 10px;
  background-color: #2491eb;
  height: 100%;
  transition: 0.5s ease;
}
</style>
