<template>
  <div
    class="step__block-partitionglass"
    v-if="activeGlassOverdoor && activeGlassThinknessOverdoor"
  >
    <GlassPriceTableRow>
      <div>
        <span>Glass sort:</span>
      </div>
      <GlassPriceTableCell>
        <span>Glass thickness:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Glass Sq.Ft:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Per sq.ft.</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>SUM</span>
      </GlassPriceTableCell>
    </GlassPriceTableRow>
    <GlassPriceTableRow class="step__block-partitionglass-block--selects">
      <div>
        <GlassSortSelect v-model="activeGlassOverdoor.structureType" />
      </div>
      <GlassPriceTableCell>
        <GlassThinknessSelect
          v-model="activeGlassThinknessOverdoor.structureType"
        />
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        {{ activeGlassOverdoor.dimensions.area.toSquareFt().format() }}
      </GlassPriceTableCell>

      <GlassPriceTableCell>
        <GlassPriceManager :pricing="activeGlassOverdoor.price" />
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>
          {{ activeGlassOverdoor.sum.getSystemPrice(dealTypeCode).format() }}
        </span>
      </GlassPriceTableCell>
    </GlassPriceTableRow>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";

import {
  GlassSortSelect,
  GlassThinknessSelect,
  GlassPriceTableCell,
  GlassPriceTableRow,
} from "@/shared";

import { GlassPriceManager } from "@/modules/glass";

import { PricingService } from "@/app/services/pricing.service";
import { DimensionValue } from "@/app/domain/entities/dimensions";

import { useOverdoorStore } from "@/stores/new/overdoor.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

const estimateStore = useNewEstimateStore();
const overdoorStore = useOverdoorStore();

const pricingService = new PricingService();

const estimate = computed(() => estimateStore.estimate);
const dealTypeCode = computed(() => estimate.value.dealTypeCode);

const activeOverdoor = computed(() => overdoorStore.activeOverdoor);
const activeGlassOverdoor = computed(() => overdoorStore.activeGlassOverdoor);

console.log("activeGlassOverdoor", activeGlassOverdoor);

const activeGlassThinknessOverdoor = computed(
  () => overdoorStore.activeGlassThinknessOverdoor,
);

const calculatePrice = () => {
  const price = pricingService.getOverdoorGlassPrices();
  activeGlassOverdoor.value?.update({ price });
};

onMounted(() => {
  if (!activeGlassOverdoor.value?.price.isEmpty()) return;

  calculatePrice();
});

watch(
  () => [activeOverdoor.value?.dimensions.area, activeOverdoor.value?.qty],
  () => {
    if (!activeGlassOverdoor.value) return;

    const overdoorArea = activeOverdoor.value?.dimensions.area.getValue() || 0;
    const qty = activeOverdoor.value?.qty || 1;
    const glassArea = overdoorArea * qty;

    activeGlassOverdoor.value.dimensions.area = new DimensionValue(glassArea);
  },
  { immediate: true },
);

watch(
  () => [
    activeGlassOverdoor.value?.structureType,
    activeGlassThinknessOverdoor.value?.structureType,
  ],
  () => {
    calculatePrice();
  },
  { deep: true },
);

watch(
  () => [
    activeGlassOverdoor.value?.price,
    activeGlassOverdoor.value?.dimensions,
  ],
  () => {
    const sum = pricingService.calculateOverdoorGlassPrice();
    activeGlassOverdoor.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}
</style>
