<template>
  <div
    v-if="header.type === 'accordion-header'"
    class="step__accordion-block-head"
  >
    <div class="step__accordion-block-container">
      <h3 v-if="Boolean(header.title)">{{ header.title }}</h3>
    </div>
    <div class="step__accordion-table-header">
      <div class="step__accordion-table-header__cell">
        {{ qty }}
      </div>
      <div class="step__accordion-table-header__cell">
        {{ price }}
      </div>
      <div class="step__accordion-table-header__cell">
        {{ sum }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import { AccordionHeader } from "@/models/summary";

import { useNewEstimateStore } from "@/stores/new/estimateStore";

type PartitionAccordionHeaderProps = {
  accordionHeader: AccordionHeader;
};

const props = defineProps<PartitionAccordionHeaderProps>();
const emit = defineEmits(["headerClickEdit", "headerClickDelete"]);

const estimateStore = useNewEstimateStore();
const dealTypeCode = computed(() => estimateStore.estimate.dealTypeCode);

const header = computed(() => props.accordionHeader);

const price = computed(
  () => header.value.price?.getSystemPrice(dealTypeCode.value).format(),
);
const sum = computed(
  () => header.value.sum?.getSystemPrice(dealTypeCode.value).format(),
);
const qty = computed(() => header.value.qty);
</script>

<style lang="scss" scoped>
.step__accordion-block-head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.step__accordion-block-head h3 {
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.98px;
  text-transform: none;
  color: #fff;
}

.step__accordion-table-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;

  font-family: Gilroy;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.41px;
  color: #fff;

  &__cell {
    text-align: right;
  }
}
</style>
