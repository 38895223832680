<template>
  <component
    v-if="line.type === 'info'"
    :is="EstimateInfoLine"
    :title="line.title"
    :value="line.value"
  />

  <component
    v-if="line.type === 'head'"
    :is="StructureHeadLine"
    :title="line.title"
    :qty="line.qty"
    :price="format(price)"
    :sum="format(sum)"
  />

  <component
    v-if="line.type === 'qtysum'"
    :is="StructureQtysumLine"
    :title="line.title"
    :qty="line.qty"
    :price="format(price)"
    :sum="format(sum)"
  />

  <component
    v-if="line.type === 'keyval'"
    :is="KeyValLine"
    :title="line.title"
    :value="line.value"
  />
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import { SummaryLine } from "@/models/summary";

import EstimateInfoLine from "./lines/EstimateInfoLine.vue";
import StructureHeadLine from "./lines/StructureHeadLine.vue";
import StructureQtysumLine from "./lines/StructureQtysumLine.vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import KeyValLine from "./lines/KeyValLine.vue";

type SummaryLineProps = {
  summaryLine: SummaryLine;
};

const props = defineProps<SummaryLineProps>();

const estimateStore = useNewEstimateStore();
const dealType = computed(() =>
  estimateStore.estimate.dealTypeCode.toUpperCase(),
);

const line = computed(() => props.summaryLine);

const price = line.value ? line.value[`price${dealType.value}`] : 0;
const sum = line.value ? line.value[`sum${dealType.value}`] : 0;

const format = (price: number) => {
  if (!price) {
    return "0.00";
  }

  return Number(price).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
</script>

<style scoped></style>
