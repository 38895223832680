<template>
  <div v-if="activeGlassPartition && activeGlassThinknessPartition">
    <GlassPriceTableRow>
      <div>
        <span>Glass sort:</span>
      </div>
      <GlassPriceTableCell>
        <span>Glass thickness:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Glass Sq.Ft:</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>Per sq.ft.</span>
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <span>SUM</span>
      </GlassPriceTableCell>
    </GlassPriceTableRow>
    <GlassPriceTableRow class="step__block-partitionglass-block--selects">
      <div>
        <GlassSortSelect v-model="activeGlassPartition.structureType" />
      </div>
      <GlassPriceTableCell>
        <GlassThinknessSelect
          v-model="activeGlassThinknessPartition.structureType"
        />
      </GlassPriceTableCell>

      <GlassPriceTableCell>
        {{ activeGlassPartition.dimensions.area.toSquareFt().format() }}
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <GlassPriceManager :pricing="activeGlassPartition.price" />
      </GlassPriceTableCell>
      <GlassPriceTableCell>
        <PriceValue :price="activeGlassPartition.sum" />
      </GlassPriceTableCell>
    </GlassPriceTableRow>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";

import { usePartitionStore } from "@/stores/new/partition.store";
import { PricingService } from "@/app/services/pricing.service";

import {
  GlassSortSelect,
  GlassThinknessSelect,
  GlassPriceTableRow,
  GlassPriceTableCell,
  PriceValue,
} from "@/shared";

import { GlassPriceManager } from "@/modules/glass";

import { useDoorsStore } from "@/stores/new/doors.store";
import { useOverdoorStore } from "@/stores/new/overdoor.store";

const partitionsStore = usePartitionStore();
const doorsStore = useDoorsStore();
const overdoorStore = useOverdoorStore();

const pricingService = new PricingService();

const activePartition = computed(() => partitionsStore.activePartitions);

const activeGlassPartition = computed(
  () => partitionsStore.activeGlassPartition,
);
const activeGlassThinknessPartition = computed(
  () => partitionsStore.activeGlassThinknessPartition,
);

const calculatePrice = () => {
  const price = pricingService.getPartitionGlassPrices();
  activeGlassPartition.value?.update({ price });
};

onMounted(() => {
  if (!activeGlassPartition.value?.price.isEmpty()) return;

  calculatePrice();
});

watch(
  () => activePartition.value?.dimensions.area,
  (partitionArea) => {
    if (!activeGlassPartition.value) return;
    activeGlassPartition.value.dimensions.area = partitionArea;
  },
);

watch(
  () => [
    activeGlassPartition.value?.structureType,
    activeGlassThinknessPartition.value?.structureType,
  ],
  () => {
    doorsStore.syncDoorGlassWithPartition();
    overdoorStore.syncOverdoorGlassWithPartition();
  },
);

watch(
  () => [
    activeGlassPartition.value?.structureType,
    activeGlassThinknessPartition.value?.structureType,
  ],
  () => {
    calculatePrice();
  },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass-subblock span {
  font-size: 14px !important;
  font-weight: normal !important;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d2d8e3;
}
</style>
