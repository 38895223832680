import {
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFTopFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            height,
        );

    const qty = width * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig()
        .qty(horizontalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);
    const height = MountsService.getTotalHeight(partition);
    const sides = 2;

    const verticalLinesQty =
        LineDividerCalculationService.calculateVerticalDividers(
            CGF350Code,
            width,
        );

    const qty = height * verticalLinesQty * sides;

    return createMountsMullionsVerticalConfig()
        .qty(verticalLinesQty)
        .area(qty)
        .build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const horizontalGasket = totalWidth * sides;
    const verticalGasket = totalHeight * sides;

    const qty = (horizontalGasket + verticalGasket) * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF350NoDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsCGFTopFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsVerticalConfig(partition),
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
