<template>
  <div class="step__block" v-if="activeSpace && activePartition">
    <div class="step__block-wrapper">
      <div class="step__block-container">
        <span class="step__block-container-left">Space description:</span>
        <SpaceDescriptionSelect v-model="activeSpace.title" />
      </div>
      <div class="step__block-container">
        <span class="step__block-container-left">Nr. # at the plan:</span>
        <input type="text" v-model="activePartition.title" placeholder="" />
      </div>
      <div class="step__block-container">
        <span class="step__block-container-left">Partition type:</span>
        <PartitionTypeSelect v-model="activePartition.structureType" />
      </div>
    </div>
    <div class="step__block-field">
      <div class="step__block-field-block">
        <h3>PARTITION dimensions:</h3>
      </div>
      <DimensionsError
        :dimensions="activePartition?.dimensions"
        :validator="ValidationService.validatePartitionDimensions"
      />
    </div>
    <PartitionDimensionsTable />
    <div class="step__block-field">
      <div class="step__block-field-block">
        <h3>Partition Glass:</h3>
      </div>
    </div>
    <div class="step__block-partitionglass">
      <GlassPartitionPriceTable />
      <div class="step__block-partitionglass-total">
        <OutOfSquarePriceTable />
      </div>
    </div>
    <PartitionPriceTotal />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import { useSpacesStore } from "@/stores/new/spaces.store";
import { usePartitionStore } from "@/stores/new/partition.store";

import {
  GlassPartitionPriceTable,
  OutOfSquarePriceTable,
  PartitionTypeSelect,
  PartitionPriceTotal,
  PartitionDimensionsTable,
} from "@/modules/partition";

import { SpaceDescriptionSelect } from "@/modules/spaces";
import { ValidationService } from "@/app/services/validation.service";
import { DimensionsError } from "@/shared";

defineExpose({ validatePage });

const spacesStore = useSpacesStore();
const partitionsStore = usePartitionStore();

const activeSpace = computed(() => spacesStore.activeSpace);
const activePartition = computed(() => partitionsStore.activePartitions);

const hasActivePartition = computed(() => partitionsStore.hasActivePartition);
const hasActiveSpace = computed(() => spacesStore.hasActiveSpace);

function validatePage() {
  return ValidationService.validatePartition(activePartition.value);
}

watch(
  () => hasActiveSpace.value,
  () => {
    if (hasActiveSpace.value) {
      return;
    }

    spacesStore.addSpace();
  },
  { immediate: true },
);

watch(
  () => hasActivePartition.value,
  () => {
    if (hasActivePartition.value) {
      return;
    }

    partitionsStore.addPartition();
  },
  { immediate: true },
);
</script>

<style scoped>
.step__block--data,
.step__block-wrapper {
  padding-right: 80px;
  display: grid;
  row-gap: 10px;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
  padding: 9px 20px;
  background-color: #fff;
  border-radius: 25px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-wrapper {
  margin-bottom: 15px;
}

.step__block-container {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-container-left {
  align-self: center;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
  display: flex;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-dimensions-right {
  display: flex;
  column-gap: 92px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-right div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
  width: 65px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-left {
  display: grid;
  grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-units {
  display: grid;
  grid-template-columns: repeat(3, 60px);
  padding-bottom: 11px;
}

.step__block-dimensions-units div {
  padding-left: 11px;
}

.step__block-dimensions-container {
  display: grid;
  grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
  background-color: #fff;
  height: 35px;
}

.step__block-dimensions-container div input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 7px;
}

.step__block-dimensions-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step__block-dimensions-block--table:first-child
  .step__block-dimensions-container
  div {
  border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
  border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table
  .step__block-dimensions-container
  div:last-child {
  border-right: none;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 40% 22% repeat(2, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-partitionglass-total {
  display: grid;
  row-gap: 15px;
}

.step__block-dimensions-warning {
  align-self: flex-start;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #ff6255;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
}
</style>
