import { KompasCustomXLinesConfigs } from "../../configs/mounts/custom-lines";
import { KompasFramelessConfigs } from "../../configs/mounts/frameless";
import { MountHardware } from "../../entities/hardware";
import { Partition } from "../../entities/partition";
import { CreateManyStrategy } from "../base/creation.strategy";

import {
    CGF300PerimeterConfigs,
    CGF350PerimeterConfigs,
    CGF400PerimeterConfigs,
    CGS200PerimeterConfigs,
    KompasPerimeterConfigs,
    TreviPerimeterConfigs,
    RomanoPerimeterConfigs,
} from "@/app/domain/configs/mounts/perimeter";

import {
    CGF300TopbottomConfigs,
    CGF350TopbottomConfigs,
    CGF400TopbottomConfigs,
    CGS200TopbottomConfigs,
    KompasTopbottomConfigs,
} from "@/app/domain/configs/mounts/topbottom";

/**
 * System: CGF300
 * Mount Type: Perimeter
 *  */

export class CGF300PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF300PerimeterConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGF300PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF300PerimeterConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGF300PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF300PerimeterConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: CGF350
 * Mount Type: Perimeter
 *  */

export class CGF350PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF350PerimeterConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGF350PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF350PerimeterConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGF350PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF350PerimeterConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: CGF400
 * Mount Type: Perimeter
 *  */

export class CGF400PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF400PerimeterConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGF400PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF400PerimeterConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGF400PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF400PerimeterConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: CGS200
 * Mount Type: Perimeter
 *  */

export class CGS200PerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGS200PerimeterConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGS200PerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGS200PerimeterConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGS200PerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGS200PerimeterConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: Kompas
 * Mount Type: Perimeter
 *  */

export class KompasPerimeterNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasPerimeterConfigs.noDoors(partition!), MountHardware);
    }
}

export class KompasPerimeterSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasPerimeterConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class KompasPerimeterSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasPerimeterConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: Kompas
 * Design System: Custom X Lines
 *  */

export class KompasCustomXLinesNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasCustomXLinesConfigs.noDoors(partition!), MountHardware);
    }
}

export class KompasCustomXLinesSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(
            KompasCustomXLinesConfigs.slidingDoors(partition!),
            MountHardware,
        );
    }
}

export class KompasCustomXLinesSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasCustomXLinesConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: Kompas
 * Design System: Frameless
 *  */

export class KompasFramelessNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasFramelessConfigs.noDoors(partition!), MountHardware);
    }
}

export class KompasFramelessSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasFramelessConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class KompasFramelessSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasFramelessConfigs.swingDoors(partition!), MountHardware);
    }
}
/**
 * System: Trevi
 * Mount Type: No mount
 *  */

export class TreviNoMountAnyDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(TreviPerimeterConfigs.anyDoors(partition!), MountHardware);
    }
}

/**
 * System: Romano
 * Mount Type: No mount
 *  */

export class RomanoNoMountAnyDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(RomanoPerimeterConfigs.anyDoors(partition!), MountHardware);
    }
}

/**
 * System: CGF300
 * Mount Type: Topbottom
 *  */

export class CGF300TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF300TopbottomConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGF300TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF300TopbottomConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGF300TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF300TopbottomConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: CGF350
 * Mount Type: Topbottom
 *  */

export class CGF350TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF350TopbottomConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGF350TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF350TopbottomConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGF350TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF350TopbottomConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: CGF400
 * Mount Type: Topbottom
 *  */

export class CGF400TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF400TopbottomConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGF400TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF400TopbottomConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGF400TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGF400TopbottomConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: CGS200
 * Mount Type: Topbottom
 *  */

export class CGS200TopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGS200TopbottomConfigs.noDoors(partition!), MountHardware);
    }
}

export class CGS200TopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGS200TopbottomConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class CGS200TopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(CGS200TopbottomConfigs.swingDoors(partition!), MountHardware);
    }
}

/**
 * System: Kompas
 * Mount Type: Topbottom
 *  */

export class KompasTopbottomNoDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasTopbottomConfigs.noDoors(partition!), MountHardware);
    }
}

export class KompasTopbottomSlidingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasTopbottomConfigs.slidingDoors(partition!), MountHardware);
    }
}

export class KompasTopbottomSwingDoorHardwaresCreation extends CreateManyStrategy {
    constructor(partition: Partition) {
        super(KompasPerimeterConfigs.swingDoors(partition!), MountHardware);
    }
}
