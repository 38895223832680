<template>
  <nav>
    <ul class="questions-section__list">
      <li v-for="step in steps" :key="step.index">
        <router-link :to="step.path" @click="stepsStore.setActiveStep(step)">
          Step {{ step.index }} {{ step.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStepsStore } from "@/stores/new/steps.store";

const stepsStore = useStepsStore();
const steps = computed(() => stepsStore.steps);
</script>

<style scoped>
.questions-section__list {
  display: grid;
  row-gap: 20px;
  margin-bottom: 30px;
}

.questions-section__list a {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-left: 0;
  transition: 0.5s ease;
}

.questions-section__list a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #2491eb;
  opacity: 0;
  transition: 0.5s ease;
}

.questions-section__list a.active-step {
  color: #fff;
  padding-left: 20px;
  transition: 0.5s ease;
}

.questions-section__list a.active-step:before {
  opacity: 1;
  transition: 0.8s ease;
}

.questions-section__list a span {
  font-weight: 400;
}
</style>
