import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { useLoginStatus, useLogin, useLogout } from "@/utils/api";
import { UserProfile } from "../models/userProfile";

export const useUserStore = defineStore("user", () => {
    const token = ref("");
    const cookie = ref("");
    const profile = ref<UserProfile | null>(null);

    const isLogged = computed(() => {
        return Boolean(profile.value && profile.value?.id > 0);
    });

    const userCode = computed(() => profile.value?.email ?? null);

    const isSalesManager = computed(() =>
        Boolean(profile.value?.roles?.some((role) => role.slug === "manager")),
    );

    const isSalesEstimator = computed(() =>
        Boolean(
            profile.value?.roles?.some((role) => role.slug === "estimator"),
        ),
    );

    const isAdmin = computed(() =>
        Boolean(profile.value?.roles?.some((role) => role.slug === "admin")),
    );

    async function login(login: string, password: string) {
        const res: any = await useLogin(login, password);

        if (res.code !== 200) return false;

        return await checkLogin();
    }

    async function logout() {
        await useLogout();
    }

    async function checkLogin() {
        try {
            const data: any = await useLoginStatus();
            if (data.code === 200 || data.code === 201) {
                profile.value = data.data;
            } else {
                profile.value = null;
            }
        } catch (err) {
            profile.value = null;
        }
    }

    return {
        token,
        cookie,
        profile,
        userCode,
        isLogged,
        isSalesManager,
        isSalesEstimator,
        isAdmin,
        login,
        logout,
        checkLogin,
    };
});
