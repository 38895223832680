import { Structure, StructureItem } from "@/models/structure";
import {
    BaseTransformer,
    convertStructureItemToReactiveComponent,
    getCalculationItem,
    getEmptyStructure,
} from "./base.transformers";
import { Partition } from "../entities/partition";
import { unwrapRef } from "@/utils/reactive";
import { CalculationItem } from "@/models/calculate";
import { GlassTransformer } from "./glass.transformers";
import { DoorTransformer } from "./door.transformer";
import { OverdoorTransformer } from "./overdoor.transformer";
import { OutOfSquareTransformer } from "./out-of-square.transformer";
import { MountsTransformer } from "./mounts.transform";

export const PartitionTransformer: BaseTransformer<StructureItem, Partition> = {
    toOld(partition: Partition): StructureItem {
        if (!partition) return getEmptyStructure();

        const outofsquare = OutOfSquareTransformer.toOld(partition);
        const glass = GlassTransformer.toOld(partition);

        return {
            id: unwrapRef(partition.id) || "",
            code: unwrapRef(partition.code) || "",
            structureTypeCode: unwrapRef(partition.structureType) || "",
            title: unwrapRef(partition.title) || "",
            qty: unwrapRef(partition.qty) || 0,
            type: unwrapRef(partition.type) || "",
            dimensions: {
                width: {
                    ft: partition.dimensions.width.ft,
                    inch: partition.dimensions.width.inch,
                    fractionA: partition.dimensions.width.fractionDivisor,
                    fractionB: partition.dimensions.width.fractionDividend,
                },
                height: {
                    ft: partition.dimensions.height.ft,
                    inch: partition.dimensions.height.inch,
                    fractionA: partition.dimensions.height.fractionDivisor,
                    fractionB: partition.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: partition.dimensions.area.toSquareFt().format(),
            },
            glassSortCode: glass?.glassSortCode || "",
            glassThicknessCode: glass?.glassThicknessCode || "",
            hardwares: [],
            note: "",
            outOfSquareQty: outofsquare.outOfSquareQty,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "",
            fillerMaterialCode: "",
            fillerAssetsQty: 0,
        };
    },
    fromOld(structure: Structure, calculations: CalculationItem[]): Partition {
        const partitionConfig = structure.partition;
        const calculationItem = getCalculationItem(
            calculations,
            partitionConfig.code,
            partitionConfig.type,
        );

        const doors = structure.doors.map((door) =>
            DoorTransformer.fromOld(
                door,
                calculationItem?.subitems.items || [],
            ),
        );

        const overdoors = structure.fillers.map((overdoor) => {
            return OverdoorTransformer.fromOld(
                overdoor,
                calculationItem?.subitems.items || [],
            );
        });

        const mounts = MountsTransformer.fromOld(
            structure.mounts,
            calculationItem?.subitems.items || [],
        );

        const partition = convertStructureItemToReactiveComponent(
            partitionConfig,
            calculations,
            Partition,
        );

        const glass = GlassTransformer.fromOld(
            partitionConfig,
            calculationItem?.subitems.items || [],
        );

        const outOfSquare = OutOfSquareTransformer.fromOld(
            partitionConfig,
            calculationItem?.subitems.items || [],
        );

        partition.addComponent(glass);
        partition.addComponent(outOfSquare);
        partition.addManyComponents(doors);
        partition.addManyComponents(overdoors);
        partition.addComponent(mounts);

        return partition;
    },
};
