<template>
  <StepAccordion
    v-for="subblock of block.subBlocks"
    :object-code="subblock.objectCode"
    :key="subblock.objectCode"
    :title="subblock.title"
    :class-name="[
      'space-accordion',
      {
        'space-accordion--editing': isPartitionEditing(
          subblock.header.objectCode,
        ),
      },
    ]"
  >
    <template #head>
      <PartitionAccordionHeader :accordion-header="subblock.accordionHeader" />
    </template>
    <div class="step__accordion-block space__accordion-block--space">
      <SummaryHeader
        v-if="subblock.header"
        :summaryHeader="subblock.header"
        @header-click-edit="emit('headerClickEdit', $event)"
        @header-click-delete="emit('headerClickDelete', $event)"
      />
      <ul v-if="subblock.lines.length" class="step__accordion-block-list">
        <SummaryLine
          v-for="line in subblock.lines"
          :key="line.code"
          :summaryLine="line"
        />
      </ul>
      <PartitionBlock v-if="subblock.subBlocks" :blocks="subblock.subBlocks" />
    </div>
  </StepAccordion>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { SummaryBlock as TSummaryBlock } from "@/models/summary";
import { usePartitionStore } from "@/stores/new/partition.store";

import StepAccordion from "@/shared/ui/StepAccordion.vue";
import PartitionAccordionHeader from "../headers/PartitionAccordionHeader.vue";

import SummaryLine from "../SummaryLine.vue";
import SummaryHeader from "../SummaryHeader.vue";
import PartitionBlock from "./PartitionBlock.vue";

type InfoBlockProps = {
  block: TSummaryBlock;
};

const partitionStore = usePartitionStore();

const activePartition = computed(() => partitionStore.activePartitions);

const props = defineProps<InfoBlockProps>();
const emit = defineEmits(["headerClickEdit", "headerClickDelete"]);

const isPartitionEditing = (id: string) => activePartition.value?.id === id;
</script>

<style scoped lang="scss">
.space-accordion {
  padding-inline: 14px;
  :deep(.step__accordion-content) {
    padding-left: 36px;
  }

  &--editing {
    background-color: rgba(69, 136, 200, 1);
  }
}

.space__accordion-block--space {
  padding-bottom: 22px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
</style>
