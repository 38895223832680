import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFTopFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const doorMultiplier = MountsService.isForDoubleDoor(partition) ? 6 : 4;
    const sides = 2;

    const verticalProfiles = totalHeight * doorMultiplier;
    const horizontalProfiles = doorWidth * sides;

    const qty = verticalProfiles + horizontalProfiles;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF300SwingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(partition),
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    // createSlidingDoorMountsCGFFrameConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
