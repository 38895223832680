import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import { ReactiveComponent } from "../../entities/base-component";
import { Door } from "../../entities/door";

export class DoorPricingStrategy extends PricingStrategy {
    public getPrices(door: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                door as Door,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                door as Door,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                door as Door,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(door: Door, dealType: string) {
        const children = door.components;

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}
