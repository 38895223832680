import { Estimate as NewEstimate } from "@/app/domain/entities/estimate";
import { Estimate as OldEstimate } from "@/models/estimate";

import { Space } from "../domain";
import { Delivery } from "../domain/entities/delivery";

import { EstimateTransformer } from "../domain/transformers/estimate.transformers";
import { CalculationItem } from "@/models/calculate";
import { getTitleFromDictByCode, useDictStore } from "@/stores/dictStore";
import { B2BDealType, B2CDealType, CostDealType } from "../constants";
import { CalculationTransformer } from "../domain/transformers/calculation.transformers";
import { SpaceTransformer } from "../domain/transformers/space.transformer";
import { DeliveryItemsTransformer } from "../domain/transformers/delivery-items.transformer";
import { OverheadItemsTransformer } from "../domain/transformers/overheads.transformers";
import { Overheads } from "../domain/entities/overhead";

export class EstimateConverterService {
    static convertToOldFormat = (
        newEstimate: NewEstimate,
        spaces: Space[],
        delivery: Delivery,
    ): OldEstimate => {
        return EstimateTransformer.toOld({
            estimate: newEstimate,
            spaces,
            delivery,
        });
    };

    static convertToOldBackedHeader(estimate: NewEstimate) {
        const dictStore = useDictStore();

        const estimatePrice = estimate.estimatePrice;
        const subtotalPrice = estimate.subtotal;
        const installedSystemCode = estimate.installedSystemCode;

        return {
            startedDate: Date.now(),
            priceDate: estimate.priceDate || new Date(),
            priceDateFormated: estimate.priceDateFormated || "",
            code: estimate.code || "",
            status: estimate.progress.status || "",
            estimateN: estimate.estimateNo || "",
            trackCode: estimate.trackCode || "",
            title: estimate.title || "",
            client: estimate.client.name || "",
            manager:
                getTitleFromDictByCode(
                    dictStore.getManagers(),
                    estimate.managerCode,
                ) || "",
            dealTypeCode:
                getTitleFromDictByCode(
                    dictStore.getDealTypes(),
                    estimate.dealTypeCode,
                ) || "",
            installationPlaceTitle:
                getTitleFromDictByCode(
                    dictStore.getInstallationPlaces(),
                    estimate.installationPlaceCode,
                ) || "",
            installedSystem:
                getTitleFromDictByCode(
                    dictStore.getSystemTypes(),
                    estimate.installedSystemCode,
                ) || "",
            usage:
                getTitleFromDictByCode(
                    dictStore.getSystemUsages(),
                    estimate.installationUsageCode,
                ) || "",
            finishColor:
                getTitleFromDictByCode(
                    dictStore.getFinishColors(),
                    estimate.colorCode,
                ) || "",
            shippingAddress:
                estimate.shippingZip + ", " + estimate.shippingState || "",
            installation: JSON.stringify(estimate.installation) || "",
            delivery: JSON.stringify(estimate.delivery) || "",
            estimator: estimate.estimatorCode
                ? estimate.estimatorCode
                : "not set",
            updatedDate: estimate.progress.updated.toLocaleString() || "",
            quantity: "1",
            price: estimatePrice.getSystemPrice(CostDealType).format(),
            priceB2C: estimatePrice.getSystemPrice(B2CDealType).format(),
            priceB2B: estimatePrice.getSystemPrice(B2BDealType).format(),
            pricePer:
                (
                    estimatePrice.getSystemPrice(CostDealType).getValue() /
                    (estimate.totalSquareFt?.getValue() || 1)
                ).toFixed(2) || "",
            subPrice: subtotalPrice
                .getSystemPrice(installedSystemCode)
                .format(),
            discount: estimate.discountPercent,
            business: estimate.client.businessName || "",
            email: estimate.client.email || "",
            phone: estimate.client.phone || "",
            state: estimate.shippingState || "",
            comments: "",
            lastValidatedPage: estimate.progress.validatedPage || 1,
        };
    }

    static convertCalculationToOldFormat(
        estimate: NewEstimate,
        spaces: Space[],
    ) {
        return CalculationTransformer.toOld(estimate, spaces);
    }

    static convertToNewFormat = (
        oldEstimate: OldEstimate,
        calculation: CalculationItem,
    ): NewEstimate => {
        return EstimateTransformer.fromOld(oldEstimate, calculation);
    };

    static convertToNewDelivery = (
        oldEstimate: OldEstimate,
        calculation: CalculationItem,
    ): Delivery => {
        const delivery = new Delivery({});
        const newDeliverItems = oldEstimate.delivery.deliveryCosts.map((item) =>
            DeliveryItemsTransformer.fromOld(item, null),
        );
        delivery.replaceDeliveryItems(newDeliverItems);

        return delivery;
    };

    static convertToNewOverheads = (
        oldEstimate: OldEstimate,
        calculation: CalculationItem,
    ): Overheads => {
        return OverheadItemsTransformer.fromOld(oldEstimate.overhead, null);
    };

    static converToNewSpaces = (
        oldEstimate: OldEstimate,
        calculation: CalculationItem,
    ): Space[] => {
        return SpaceTransformer.fromOld(oldEstimate, calculation);
    };
}
