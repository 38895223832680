import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsSideFrameSortConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsTopFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totaleHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totaleHeight * sides;

    return createMountsSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = totalWidth * sides;
    const verticalGaskets = totalHeight * sides;

    const qty = (horizontalGaskets + verticalGaskets) * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsStripesConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getStipJointsWithoutDoorQty(partition);

    return createMountsStripesConfig().qty(0).area(qty).build();
};

export const CGS200NoDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsTopFrameSortConfig(partition),
    createSlidingDoorMountsSideFrameSortConfig(partition),
    createSlidingDoorMountsBottomFrameSortConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
    createSlidingDoorMountsStripesConfig(partition),
];
