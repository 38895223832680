import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsToptrackSlidingDoorConfig = (
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(0)
        .area(1)
        .build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;
    const qty = height * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const cgfSidesQty = 4;
    const doorMultiplier = MountsService.isForDoubleDoor(partition) ? 4 : 1;

    const qty = totalHeight * cgfSidesQty * doorMultiplier;

    return createMountsCGFFrameConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF300SlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsToptrackSlidingDoorConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    // createSlidingDoorMountsCGFFrameConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
