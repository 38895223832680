import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { defineStore } from "pinia";

import OneStep from "@/components/stepsData/OneStep.vue";
import TwoStep from "@/components/stepsData/TwoStep.vue";
import ThreeStep from "@/components/stepsData/ThreeStep.vue";
import FourStep from "@/components/stepsData/FourStep.vue";
import FiveStep from "@/components/stepsData/FiveStep.vue";
import FiveOneStep from "@/components/stepsData/FiveOneStep.vue";
import SixStep from "@/components/stepsData/SixStep.vue";
import SevenStep from "@/components/stepsData/SevenStep.vue";
import EightStep from "@/components/stepsData/EightStep.vue";
import { useActionsStrore } from "./actions.store";

type Step = {
    key: number;
    index: string;
    name: string;
    path: string;
    component: any;
};

export const useStepsStore = defineStore("steps", () => {
    const router = useRouter();
    const route = useRoute();

    const actionsStore = useActionsStrore();

    const activeStep = ref<Step | null>(null);
    const activeStepComponentRef = ref<any>(null);

    const steps: Step[] = [
        {
            index: "1",
            key: 1,
            name: "General information",
            path: "/calculation/1",
            component: OneStep,
        },
        {
            index: "2",
            key: 2,
            name: "Partition(s)",
            path: "/calculation/2",
            component: TwoStep,
        },
        {
            index: "3",
            key: 3,
            name: "Door(s)",
            path: "/calculation/3",
            component: ThreeStep,
        },
        {
            index: "4",
            key: 4,
            name: "Transom(s) and filler(s)",
            path: "/calculation/4",
            component: FourStep,
        },
        {
            index: "5",
            key: 5,
            name: "Chanel, tracks, profiles",
            path: "/calculation/5",
            component: FiveStep,
        },
        {
            index: "5.1",
            key: 6,
            name: "Partition Q-ty",
            path: "/calculation/5.1",
            component: FiveOneStep,
        },
        {
            index: "6",
            key: 7,
            name: "Overhead expenses",
            path: "/calculation/6",
            component: SixStep,
        },
        {
            index: "7",
            key: 8,
            name: "Delivery and installation",
            path: "/calculation/7",
            component: SevenStep,
        },
        {
            index: "8",
            key: 9,
            name: "Estimation review",
            path: "/calculation/8",
            component: EightStep,
        },
    ];

    const setActiveStep = (step: Step) => {
        activeStep.value = step;
    };

    const setActiveStepComponent = (component: any) => {
        activeStepComponentRef.value = component;
    };

    const goToStep = (index: string) => {
        const step = steps.find((step) => step.index === index);

        if (!step) return;

        router.push(step.path);
    };

    const next = async () => {
        try {
            const nextStep = getNextStep();

            if (!nextStep) return;

            const canNext = await beforeNext();

            if (!canNext) return;

            router.push(nextStep.path);
        } catch (e) {
            console.error("Validation error!");
        }
    };

    const prev = () => {
        const prevStep = getPrevStep();

        if (!prevStep) return;

        router.push(prevStep.path);
    };

    const restoreStep = (path: string) => {
        const step = getStepByPath(path);

        if (!step) return;

        actionsStore.reloadSummary();
        setActiveStep(step);
    };

    const beforeNext = () => {
        return new Promise((resolve, reject) => {
            const componentInstance = activeStepComponentRef.value;

            if (!componentInstance) return resolve(true);

            const hasValidation =
                componentInstance.hasOwnProperty("validatePage");

            if (!hasValidation) return resolve(true);

            const errorMessage = hasValidation
                ? componentInstance.validatePage()
                : null;

            if (errorMessage) {
                alert(
                    errorMessage ||
                        "Validation error\nSome of the required fields are not filled correctly",
                );

                return reject();
            }

            return resolve(true);
        });
    };

    const getStepByPath = (path: string) => {
        const currentIndex = steps.findIndex((step) => step.path === path);

        return steps[currentIndex];
    };

    const getNextStep = () => {
        const currentIndex = steps.findIndex(
            (step) => step.path === activeStep.value?.path,
        );

        if (currentIndex !== -1 && currentIndex < steps.length - 1) {
            return steps[currentIndex + 1];
        }

        return null;
    };

    const getPrevStep = () => {
        const currentIndex = steps.findIndex(
            (step) => step.path === activeStep.value?.path,
        );

        if (currentIndex > 0) {
            return steps[currentIndex - 1];
        }

        return null;
    };

    watch(() => route.path, restoreStep, { immediate: true });

    return {
        steps,
        activeStep,

        setActiveStepComponent,
        setActiveStep,
        next,
        prev,
        goToStep,
    };
});
