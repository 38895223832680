import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { Delivery } from "@/app/domain/entities/delivery";
import { DeliveryService } from "@/app/services/delivery.service";

export const useDeliveryStore = defineStore(
    "delivery-store",
    () => {
        const activeDelivery = ref<Delivery | null>(null);

        const $restoreDelivery = (deliveryFromApi: Delivery) => {
            activeDelivery.value = deliveryFromApi;
        };

        const $reset = () => {
            activeDelivery.value = null;
        };

        const hasActiveDelivery = computed(() => activeDelivery.value !== null);

        const addDelivery = () => {
            const delivery = new Delivery({
                qty: 1,
            });

            const deliverItems = DeliveryService.createDeliveryItems();
            delivery.replaceDeliveryItems(deliverItems);

            activeDelivery.value = delivery;
        };

        return {
            activeDelivery,
            hasActiveDelivery,
            addDelivery,
            $reset,
            $restoreDelivery,
        };
    },
    { persist: true },
);
