import { StructureItem } from "@/models/structure";
import { ReactiveComponent } from "../entities/base-component";
import { CalculationItem } from "@/models/calculate";

export interface BaseTransformer<
    TOldResponse = StructureItem,
    TNewResponse = ReactiveComponent,
    TOldRequest = ReactiveComponent,
> {
    toOld(component: TOldRequest): TOldResponse;
    fromOld(component: unknown, calculation: CalculationItem[]): TNewResponse;
}

export const convertStructureItemToReactiveComponent = (
    config: StructureItem,
    calculations: CalculationItem[],
    Component: any,
) => {
    const price = getPriceFromCalculationItem(
        calculations,
        config.type || "",
        config.code,
    );

    const sum = getSumFromCalculationItem(
        calculations,
        config.type || "",
        config.code,
    );

    return new Component({
        id: config.id,
        code: config.code,
        title: config.title,
        typeCode: config.structureTypeCode,
        structureType: config.structureTypeCode,
        qty: config.qty,
        dimensions: getDimensionsFromOldStructure(config),
        price,
        sum,
    });
};

export const getPriceFromCalculationItem = (
    items: CalculationItem[],
    type: string,
    code?: string,
) => {
    const calcuationItem = getCalculationItem(items, code, type);

    return {
        price: calcuationItem?.price || 0,
        priceB2C: calcuationItem?.priceB2C || 0,
        priceB2B: calcuationItem?.priceB2B || 0,
    };
};

export const getSumFromCalculationItem = (
    items: CalculationItem[],
    type: string,
    code?: string,
) => {
    const calcuationItem = getCalculationItem(items, code, type);

    return {
        price: calcuationItem?.sum || 0,
        priceB2C: calcuationItem?.sumB2C || 0,
        priceB2B: calcuationItem?.sumB2B || 0,
    };
};

export const getDimensionsFromOldStructure = (config: StructureItem) => {
    return {
        width: {
            ft: config.dimensions.width.ft,
            inch: config.dimensions.width.inch,
            fractionDivisor: config.dimensions.width.fractionA,
            fractionDividend: config.dimensions.width.fractionB,
        },
        height: {
            ft: config.dimensions.height.ft,
            inch: config.dimensions.height.inch,
            fractionDivisor: config.dimensions.height.fractionA,
            fractionDividend: config.dimensions.height.fractionB,
        },
    };
};

export const getCalculationItem = (
    items: CalculationItem[],
    code?: string,
    type?: string,
) => {
    if (type === "mounts") {
        return items.find((item) => item.elementType === "mounts");
    }

    if (type === "glassPartition") {
        return items.find((item) => item.elementType === "glassPartition");
    }

    return items.find(
        (item) => item.elementType === type && item.elementCode === code,
    );
};

export const getEmptyStructure = () => {
    return {
        id: "",
        code: "",
        structureTypeCode: "",
        title: "",
        qty: 0,
        type: "",
        dimensions: {
            width: {
                ft: 0,
                inch: 0,
                fractionA: 0,
                fractionB: 0,
            },
            height: {
                ft: 0,
                inch: 0,
                fractionA: 0,
                fractionB: 0,
            },
            perimeterFt: 0,
            areaFt: 0,
        },
        glassSortCode: "",
        glassThicknessCode: "",
        hardwares: [],
        note: "",
        outOfSquareQty: 0,
        additionalCosts: [],
        // filler specific
        fillerTypeCode: "", // glass , aluminum sheet, other
        fillerMaterialCode: "",
        fillerAssetsQty: 0,
    };
};
