<template>
  <div class="step__block-sumperdoor" v-if="activeDoor">
    <div class="step__block-sumperdoor-left">
      <div>
        <span>Door quantity and the SUM per one door:</span>
      </div>
    </div>
    <div class="step__block-sumperdoor-right">
      <div class="step__block-sumperdoor-input">
        <input
          v-model.number="activeDoor.qty"
          type="number"
          min="0"
          max="99"
          step="1"
        />
      </div>
      <div class="step__block-sumperdoor-price">
        <span>{{ activeDoor.price.getSystemPrice(dealType).format() }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import { useDoorsStore } from "@/stores/new/doors.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { PricingService } from "@/app/services/pricing.service";

const pricingService = new PricingService();

const doorStore = useDoorsStore();
const estimateStore = useNewEstimateStore();

const dealType = computed(() => estimateStore.estimate.dealTypeCode);
const activeDoor = computed(() => doorStore.activeDoor);

const setDoorPrice = () => {
  if (!activeDoor.value?.isEditable()) {
    return;
  }

  setTimeout(() => {
    const price = pricingService.getDoorPrices();

    activeDoor.value?.update({
      price,
    });
    50;
  });
};

watch(() => activeDoor.value?.structureType, setDoorPrice, { immediate: true });
watch(() => activeDoor.value?.components, setDoorPrice, { deep: true });
</script>

<style lang="scss" scoped>
.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.step__block-sumperdoor {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-sumperdoor-right {
  display: flex;
  align-items: center;
  column-gap: 129px;
}

.step__block-sumperdoor-input input {
  border-radius: 20px;
  background: #fff;
  width: 50px;
  height: 30px;
  color: #404d63;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.step__block-hardware-first {
  align-items: flex-start !important;
}

.step__block-hardware {
  padding: 15px 0;
}

.step__block-hardware-container {
  display: grid;
  row-gap: 5px;
}

.step__block-hardware-container.step__block-hardware-container--cuts {
  row-gap: 10px;
}

.step__block-hardware-block {
  display: grid;
  grid-template-columns: 1fr 50% repeat(3, 1fr);
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-hardware-head {
  margin-bottom: 13px;
}

.step__block-hardware-block.step__block-hardware-head
  .step__block-hardware-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-hardware-block.step__block-hardware-content
  .step__block-hardware-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.step__block-hardware-qt div {
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  text-align: center;
}

.step__block-field {
  display: flex;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-dimensions-right {
  display: flex;
  column-gap: 92px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-right div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
  width: 65px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-left {
  display: grid;
  grid-template-columns: minmax(0, 46px) repeat(2, 1fr);
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-units {
  display: grid;
  grid-template-columns: repeat(3, 60px);
  padding-bottom: 11px;
}

.step__block-dimensions-units div {
  padding-left: 11px;
}

.step__block-dimensions-container {
  display: grid;
  grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
  background-color: #fff;
  height: 35px;
}

.step__block-dimensions-container div input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 7px;
}

.step__block-dimensions-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step__block-dimensions-block--table:first-child
  .step__block-dimensions-container
  div {
  border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
  border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table
  .step__block-dimensions-container
  div:last-child {
  border-right: none;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 35% 155px repeat(3, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 50px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
}

.step__block-partitionglass-total {
  display: grid;
  row-gap: 15px;
}

.step__block-dimensions-warning {
  align-self: flex-start;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #ff6255;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
}
</style>
