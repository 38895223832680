<template>
  <div class="step__block-doortype">
    <div><span>Design</span></div>
    <select-dict
      v-model="mountStructureType"
      :select-list="mountsTypes"
      :select-first-item="true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";
import { KompasFrameCode, KompasFramelessCode } from "@/app/constants";

import SelectDict from "@/components/UI/SelectDict.vue";
import { usePartitionStore } from "@/stores/new/partition.store";
import { GlassThinknessPartition } from "@/app/domain";

const partitionStore = usePartitionStore();
const activeGlassPartition = computed(
  () =>
    partitionStore.activePartitions?.getActiveGlassThinkness() as GlassThinknessPartition,
);

const mountStructureType = defineModel<string>();
const mountsTypes = computed(() => {
  if (activeGlassPartition.value.is3_8Glass()) {
    return [
      { code: KompasFrameCode, title: "Custom X Lines" },
      { code: KompasFramelessCode, title: "Frameless" },
    ];
  }
  return [{ code: KompasFrameCode, title: "Custom X Lines" }];
});
</script>

<style lang="scss" scoped>
.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}
</style>
