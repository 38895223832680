<template>
  <input type="number" v-model="price" @blur="emit('stop:editing')" autofocus />
</template>

<script setup lang="ts">
const price = defineModel();
const emit = defineEmits(["stop:editing"]);
</script>

<style scoped lang="scss">
input {
  width: 52px;
  padding: 4px 6px;
  border-radius: 2px;

  &:focus {
    outline: 1px solid #3b99fc;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
