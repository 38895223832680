import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsStripesConfig,
    createMountsToptrackConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsToptrackConfig = (
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(1)
        .build();
};

const createSlidingDoorMountsBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const qty = totalWidth - doorWidth;

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const hasTransom = MountsService.hasOverdoorTransom(partition);
    const sides = 2;

    const doorDeduction = doorWidth - (hasTransom ? 0 : doorWidth);

    const qty = (totalWidth * sides - doorDeduction) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsStripesConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(partition);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200SlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsToptrackConfig(partition),
    createSlidingDoorMountsBottomFrameSortConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
    createSlidingDoorMountsStripesConfig(partition),
];
