<template>
  <div
    class="step__block-labels"
    :class="{ 'step__block-labels--disabled': disabled }"
  >
    <label class="step__block-label">
      <input
        v-bind="$attrs"
        v-model="model"
        :value="true"
        type="radio"
        :disabled="disabled"
      />
      <span>{{ yesLabel }}</span>
    </label>
    <label class="step__block-label">
      <input
        v-bind="$attrs"
        v-model="model"
        :value="false"
        type="radio"
        :disabled="disabled"
      />
      <span>{{ noLabel }}</span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { defineModel } from "vue";

type RadioGroupProps = {
  disabled?: boolean;
  yesLabel?: string;
  noLabel?: string;
};

const props = withDefaults(defineProps<RadioGroupProps>(), {
  disabled: false,
  yesLabel: "yes",
  noLabel: "no",
});
const model = defineModel<boolean>({ default: false });
</script>

<style lang="scss" scoped>
.step__block-labels--disabled {
  opacity: 0.5;

  input {
    cursor: not-allowed;
    pointer-events: none;
  }
}

input[type="radio"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(217, 223, 229, 1) !important;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  -webkit-transform: translateY(-0.075em);
  -ms-transform: translateY(-0.075em);
  transform: translateY(-0.075em);
}

input[type="radio"] {
  display: -ms-grid;
  padding: 0 !important;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 120ms -webkit-transform ease-in-out;
  transition: 120ms -webkit-transform ease-in-out;
  -o-transition: 120ms transform ease-in-out;
  transition: 120ms transform ease-in-out;
  transition:
    120ms transform ease-in-out,
    120ms -webkit-transform ease-in-out;
  background: #fff;
}

input[type="radio"]:checked::before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background: #fff;
}

.step__block {
  border-bottom: 1px solid #d2d8e3;
  padding-bottom: 20px;
}

.step__block--data,
.step__block-wrapper {
  padding-right: 80px;
  display: grid;
  row-gap: 10px;
}

.step__block-container {
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 10px;
}

.step__block-container-left {
  align-self: center;
}

.step__block-labels {
  display: flex;
  column-gap: 100px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-label {
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-width: 150px;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
  padding: 9px 20px;
  background-color: #fff;
  border-radius: 25px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
