import { DoorType, MountType, SystemType } from "@/app/@types/shared";
import { Partition } from "../domain";
import { MountHardware } from "../domain/entities/hardware";
import { MountHardwareStrategyRegistry } from "./mount-hardwares-strategies.service";
import { useDictStore } from "@/stores/dictStore";
import { partition } from "lodash";

type TopTrack = {
    structureCode: string;
    qty: number;
};

type CreateMountsHardwaresArgs = {
    installedSystemCode: string;
    activeDoorStructureType: string;
    activeMountStructureType: string;
    partition: Partition;
};

export class MountsService {
    static createMountsHardwares({
        installedSystemCode,
        activeDoorStructureType,
        activeMountStructureType,
        partition,
    }: CreateMountsHardwaresArgs): MountHardware[] {
        if (!partition) {
            return [];
        }

        const mountsStrategiesRegiest = new MountHardwareStrategyRegistry();

        const Strategy = mountsStrategiesRegiest.getStrategy(
            installedSystemCode as SystemType,
            activeDoorStructureType as DoorType,
            activeMountStructureType as MountType,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy(partition);
        return strategy.create() as MountHardware[];
    }

    static getTotalWidth(partition: Partition): number {
        return partition.dimensions.width.getLength().toFt().getValue();
    }

    static getTotalHeight(partition: Partition): number {
        return partition.dimensions.height.getLength().toFt().getValue();
    }

    static getPertimetr(partition: Partition): number {
        const width = this.getTotalWidth(partition);
        const height = this.getTotalHeight(partition);

        return width * 2 + height * 2;
    }

    static getDoorTotalWidth(partition: Partition): number {
        const door = partition.getActiveDoor();

        return door.dimensions.width.getLength().toFt().getValue();
    }

    static getDoorTotalHeight(partition: Partition): number {
        const door = partition.getActiveDoor();

        return door.dimensions.height.getLength().toFt().getValue();
    }

    static getTopFrameWidth(partition: Partition): number {
        return this.getPartitionWidthWithoutDoor(partition);
    }

    static getBottomFrameWidth(partition: Partition): number {
        return this.getPartitionWidthWithoutDoor(partition);
    }

    static getPartitionWidthWithoutDoor(partition: Partition): number {
        return (
            this.getTotalWidth(partition) - this.getDoorTotalWidth(partition)
        );
    }

    static getTransomHeight(partition: Partition): number {
        const overdoor = partition.getActiveOverdoor();

        if (overdoor.isTransom()) {
            return overdoor.dimensions.height.getLength().toFt().getValue();
        }

        return 0;
    }

    static getFramelessPertimetr(partition: Partition): number {
        const perimeter = this.getPertimetr(partition);
        const doorWidth = this.getDoorTotalWidth(partition);

        return perimeter + doorWidth;
    }

    static getStipJointsQty = (partition: Partition): number => {
        const totalWidth = this.getTotalWidth(partition);
        const doorWidth = this.getDoorTotalWidth(partition);

        const doorSections = 3;

        return Math.round((totalWidth - doorWidth) / doorSections) - 1;
    };

    static getStipJointsWithoutDoorQty = (partition: Partition): number => {
        const totalWidth = this.getTotalWidth(partition);
        const doorWidth = this.getDoorTotalWidth(partition);

        const doorSections = 3;

        return Math.round(totalWidth - doorWidth) / doorSections;
    };

    static getTopTrack = (partition: Partition): string => {
        const dictStore = useDictStore();
        const width = this.getTotalWidth(partition);
        const topTrack = dictStore.findMullionTrack(width);

        return topTrack?.code || "";
    };

    static getKompasVerticalLines = (partition: Partition): number => {
        const width = MountsService.getTotalWidth(partition);

        const step = 3;
        const sideLine = 1;

        const qty = Math.floor(width / step) - sideLine;

        return qty;
    };

    static getKompasHorizontalLines = (partition: Partition): number => {
        return 1;
    };

    static getKompasVerticalLinesLength = (
        partition: Partition,
        lines: number,
    ): number => {
        return lines * this.getTotalHeight(partition);
    };

    static getKompasHorizontallLinesLength = (
        partition: Partition,
        lines: number,
    ): number => {
        return lines * this.getTotalWidth(partition);
    };

    static getKompasVerticalCopolymerLines = (partition: Partition): number => {
        const width = MountsService.getTotalWidth(partition);

        const step = 3;
        const sideLine = 1;

        const qty = Math.floor(width / step) - sideLine;

        return qty;
    };

    static getKompasVerticalCopolymerLinesLength = (
        partition: Partition,
        lines: number,
    ): number => {
        return lines * this.getTotalHeight(partition);
    };

    static isForDoubleDoor = (partition: Partition): boolean => {
        const door = partition.getActiveDoor();

        return door.isDoubleDoor();
    };

    static hasOverdoorTransom = (partition: Partition): boolean => {
        const overdoor = partition.getActiveOverdoor();

        return overdoor.isTransom();
    };
}
