import { IPricing, IPricingValue } from "@/app/@types/pricing";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { DealTypePredicates } from "@/app/predicates";
import { isRef, ref, Ref, toRaw } from "vue";

export class Pricing implements IPricing {
    price: Ref<number>;
    priceB2C: Ref<number>;
    priceB2B: Ref<number>;

    constructor(price = 0, priceB2C = 0, priceB2B = 0) {
        this.price = ref(price);
        this.priceB2C = ref(priceB2C);
        this.priceB2B = ref(priceB2B);
    }

    getSystemPrice(system: string): IPricingValue {
        const fieldName = this.getPriceKeyBySystem(system);
        const field = toRaw(this)[fieldName];

        if (!field) {
            return new PricingValue(0);
        }

        return new PricingValue(field.value);
    }

    setSystemPrice(system: string, value: number) {
        const fieldName = this.getPriceKeyBySystem(system);
        const field = toRaw(this)[fieldName];

        field.value = value;
    }

    setPrice(value: number): void {
        this.setSystemPrice(CostDealType, value);
        this.setSystemPrice(B2BDealType, value);
        this.setSystemPrice(B2CDealType, value);
    }

    update(data: Record<string, number>) {
        Object.entries(data).forEach(([key, value]) => {
            if (!(key in this)) {
                return;
            }

            const field = toRaw(this)[key];
            field.value = value;
        });
    }

    serialize = (): Record<string, number> => {
        return {
            price: this.unwrapRef(this.price.value),
            priceB2C: this.unwrapRef(this.priceB2C.value),
            priceB2B: this.unwrapRef(this.priceB2B.value),
        };
    };

    deserialize(data: Record<string, number>) {
        this.update(data);
    }

    isEmpty(): boolean {
        const price = this.getSystemPrice(CostDealType).getValue();
        const priceB2C = this.getSystemPrice(B2CDealType).getValue();
        const priceB2B = this.getSystemPrice(B2BDealType).getValue();

        return !price && !priceB2C && !priceB2B;
    }

    private getPriceKeyBySystem(system: string): string {
        if (DealTypePredicates.isCostDealType(system)) {
            return "price";
        }

        return `price${system.toUpperCase()}`;
    }

    private unwrapRef(value: any) {
        return isRef(value) ? value.value : value;
    }
}

export class PricingValue implements IPricingValue {
    price: number;

    constructor(price: number) {
        this.price = price;
    }

    getValue(): number {
        return this.price;
    }

    format(options?: Intl.NumberFormatOptions): string {
        if (!this.price) return "0.00";
        return Number(this.price).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            ...options,
        });
    }
}
