import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { Extra, ExtraItem } from "@/app/domain/entities/extra";
import { usePartitionStore } from "./partition.store";
import { unwrapRef } from "@/utils/reactive";
import { PricingService } from "@/app/services/pricing.service";

export const useExtraStore = defineStore(
    "extra-store",
    () => {
        const partitionsStore = usePartitionStore();
        const activePartition = computed(
            () => partitionsStore.activePartitions,
        );

        const activeExtra = ref<Extra | null>(null);
        const activeExtraItems = ref<ExtraItem[]>([]);

        const hasActiveExtra = computed(() => activeExtra.value !== null);

        const $reset = () => {
            activeExtra.value = null;
            activeExtraItems.value = [];
        };

        const addExtra = () => {
            if (!activePartition.value) {
                return;
            }

            const extra = new Extra({
                qty: 1,
            });

            activePartition.value.addComponent(extra);
            activeExtra.value = extra;
        };

        const addExtraItem = () => {
            if (!activeExtra.value) {
                return;
            }

            const extraItem = new ExtraItem({
                qty: 1,
            });

            activeExtra.value.addComponent(extraItem);
            activeExtraItems.value.push(extraItem);
        };

        const removeExtraItem = (extraItem: ExtraItem) => {
            if (!activeExtra.value) {
                return;
            }

            const newComponents = activeExtra.value.removeComponent(
                unwrapRef(extraItem.id),
                unwrapRef(extraItem.type).value,
            ) as ExtraItem[];

            activeExtraItems.value = newComponents;
        };

        return {
            activeExtra,
            activeExtraItems,
            hasActiveExtra,
            $reset,
            addExtra,
            addExtraItem,
            removeExtraItem,
        };
    },
    { persist: true },
);
