<template>
  <component
    :is="hardwareComponent"
    :hardware="hardware"
    v-model:structureType="hardware.structureType"
    v-model:qty="hardware.qty"
  />
</template>

<script setup lang="ts">
import { defineProps, defineModel, computed } from "vue";

import { MountHardware } from "@/app/domain/entities/hardware";

import DefaultHardwareItem from "./mount-hardware/DefaultHardwareItem.vue";
import {
  MountsGlazingGasketTypeCode,
  MountsKompasCopolymerVerticalTypeCode,
  MountsKompasFrameTypeCode,
  MountsKompasHorizontalTypeCode,
  MountsKompasVerticalTypeCode,
} from "@/app/constants/mounts";

import PerimetrFrameHardwareItem from "./mount-hardware/PerimetrFrameHardwareItem.vue";
import HorizontalLineHardwareItem from "./mount-hardware/HorizontalLineHardwareItem.vue";
import GlazingGasketHardwareItem from "./mount-hardware/GlazingGasketHardwareItem.vue";
import VerticalLineHardwareItem from "./mount-hardware/VerticalLineHardwareItem.vue";
import VerticalCopolymerHardwareItem from "./mount-hardware/VerticalCopolymerHardwareItem.vue";

import { unwrapRef } from "@/utils/reactive";

type MountHardwareItemProps = {
  hardware: MountHardware;
};

const structureType = defineModel<string>("structureType");
const qty = defineModel<number>("qty");
const props = defineProps<MountHardwareItemProps>();

const hardwareComponents = {
  [MountsKompasFrameTypeCode]: PerimetrFrameHardwareItem,
  [MountsKompasHorizontalTypeCode]: HorizontalLineHardwareItem,
  [MountsKompasVerticalTypeCode]: VerticalLineHardwareItem,
  [MountsGlazingGasketTypeCode]: GlazingGasketHardwareItem,
  [MountsKompasCopolymerVerticalTypeCode]: VerticalCopolymerHardwareItem,
  default: DefaultHardwareItem,
};

const hardwareComponent = computed(
  () =>
    hardwareComponents[unwrapRef(props.hardware.typeCode)] ||
    hardwareComponents.default,
);
</script>

<style lang="scss" scoped>
.step__block-price {
  font-weight: normal !important;
}

.step__block-wrapper {
  display: grid;
  row-gap: 5px;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
}

.step__block-count {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 15px;
}

.step__block-container {
  display: grid;
  grid-template-columns: 200px 200px 60px 92px repeat(2, 1fr);
  column-gap: 15px;
}

.step__block-wrapper--bottom .step__block-container {
  grid-template-columns: 200px 200px 60px 92px repeat(2, 1fr);
}

.step__block-container--top {
  margin-bottom: 10px;
}

.step__block-container div:first-child {
  align-items: flex-start;
}

.step__block-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.step__block-container div.step__block-div {
  align-items: center;
}

.step__block-container div input {
  text-align: center;
  padding: 0 10px;
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}

.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.select {
  margin-right: 20px;
}
</style>
