import { AdditionalCostItem } from "@/models/structure";
import { BaseTransformer } from "./base.transformers";
import { DeliveryItem } from "../entities/delivery";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { unwrapRef } from "@/utils/reactive";

export const DeliveryItemsTransformer: BaseTransformer<
    AdditionalCostItem,
    DeliveryItem
> = {
    toOld(deliveryItem: DeliveryItem) {
        return {
            code: unwrapRef(deliveryItem.code),
            costTypeCode: unwrapRef(deliveryItem.typeCode),
            costValueCode: unwrapRef(deliveryItem.structureType),
            title: unwrapRef(deliveryItem.title),
            description: "",
            unit: unwrapRef(deliveryItem.unit),
            qty: unwrapRef(deliveryItem.qty),
            price: deliveryItem.price.getSystemPrice(CostDealType).getValue(),
            priceB2C: deliveryItem.price.getSystemPrice(B2CDealType).getValue(),
            priceB2B: deliveryItem.price.getSystemPrice(B2BDealType).getValue(),
            sum: deliveryItem.sum.getSystemPrice(CostDealType).getValue(),
        };
    },
    fromOld(additionalCost: AdditionalCostItem) {
        return new DeliveryItem({
            code: additionalCost.code,
            typeCode: additionalCost.costTypeCode,
            structureType: additionalCost.costValueCode,
            title: additionalCost.title,
            unit: additionalCost.unit,
            qty: additionalCost.qty,
            price: {
                price: additionalCost.price,
                priceB2C: additionalCost.priceB2C,
                priceB2B: additionalCost.priceB2B,
            },
            sum: {
                price: additionalCost.sum,
                priceB2C: additionalCost.sum,
                priceB2B: additionalCost.sum,
            },
        });
    },
};
