import {
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsCGFSideFrameSortConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";

import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFTopFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFSideFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsCGFSideFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsCGFBottomFrameSortConfig().qty(0).area(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

export const CGF300NoDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsCGFSideFrameSortConfig(partition),
    createSlidingDoorMountsCGFTopFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
