import { StructureItem } from "@/models/structure";
import {
    BaseTransformer,
    convertStructureItemToReactiveComponent,
    getCalculationItem,
    getEmptyStructure,
} from "./base.transformers";
import { unwrapRef } from "@/utils/reactive";
import { GlassTransformer } from "./glass.transformers";
import { Door } from "../entities/door";
import { HardwareTransformer } from "./hardware.transform";
import { Hardware } from "../entities/hardware";

export const DoorTransformer: BaseTransformer<StructureItem, Door> = {
    toOld(door: Door): StructureItem {
        if (!door) return getEmptyStructure();

        const hardwares = door.getActiveHardwares() as unknown as Hardware[];
        const glass = door.getActiveGlassPartition();
        const glassThickness = door.getActiveGlassThinkness();

        return {
            id: unwrapRef(door.id) || "",
            code: unwrapRef(door.code) || "",
            structureTypeCode: unwrapRef(door.structureType) || "",
            title: unwrapRef(door.title) || "",
            qty: unwrapRef(door.qty) || 0,
            type: unwrapRef(door.type) || "",
            dimensions: {
                width: {
                    ft: door.dimensions.width.ft,
                    inch: door.dimensions.width.inch,
                    fractionA: door.dimensions.width.fractionDivisor,
                    fractionB: door.dimensions.width.fractionDividend,
                },
                height: {
                    ft: door.dimensions.height.ft,
                    inch: door.dimensions.height.inch,
                    fractionA: door.dimensions.height.fractionDivisor,
                    fractionB: door.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: door.dimensions.area.toSquareFt().format(),
            },
            glassSortCode: glass?.structureType || "",
            glassThicknessCode: glassThickness?.structureType || "",
            hardwares: hardwares.map(HardwareTransformer.toOld),
            note: "",
            outOfSquareQty: 0,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "", // glass , aluminum sheet, other
            fillerMaterialCode: "",
            fillerAssetsQty: 0,
        };
    },
    fromOld(doorConfig: StructureItem, calculations) {
        const calculationItem = getCalculationItem(
            calculations,
            doorConfig.code,
            doorConfig.type,
        );

        const door = convertStructureItemToReactiveComponent(
            doorConfig,
            calculations,
            Door,
        );

        const glass = GlassTransformer.fromOld(
            doorConfig,
            calculationItem?.subitems.items || [],
        );

        const hardwares = doorConfig.hardwares.map((hardware) =>
            HardwareTransformer.fromOld(
                hardware,
                calculationItem?.subitems.items || [],
            ),
        );

        door.addManyComponents(hardwares);
        door.addComponent(glass);

        return door;
    },
};
