<template>
  <div class="">
    <select-dict v-model="shippingState" :select-list="states" />
    <p class="hint-message" v-if="needHintMessage">Contact the estimator</p>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel, watch } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
const dictStore = useDictStore();

const shippingState = defineModel<string>("state", { default: "" });

const states = dictStore.getUsStates();

const needHintMessage = computed(() =>
  ["AK", "HI"].includes(shippingState.value),
);
</script>

<style lang="scss" scoped>
.hint-message {
  display: flex;
  justify-self: flex-end;
  font-size: 16px;
  color: red;
  margin-top: 8px;
}
</style>
