<template>
  <div class="step__block-field">
    <div class="step__block-field-block">
      <h3>page Total:</h3>
      <span class="step__block-field-price" v-if="total">
        {{ total.getSystemPrice(dealTypeCode).format() }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { Pricing } from "@/app/domain";
import { PricingService } from "@/app/services/pricing.service";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useSpacesStore } from "@/stores/new/spaces.store";

const estimateStore = useNewEstimateStore();
const spacesStore = useSpacesStore();

const pricingService = new PricingService();
const total = ref<Pricing>();

const estimate = computed(() => estimateStore.estimate);
const dealTypeCode = computed(() => estimate.value.dealTypeCode);
const spaces = computed(() => spacesStore.spaces);

watch(
  () => spaces.value.map((space) => space.sum),
  () => {
    const price = pricingService.calculateSpaceTotalPrice();
    total.value = new Pricing(price.price, price.priceB2B, price.priceB2C);
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
  display: flex;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
</style>
