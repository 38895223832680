<template>
  <div class="step__block-container">
    <div>
      <span>{{ hardware.title }}:</span>
    </div>
    <div>
      <MountHardwareValueSelect
        v-model="structureType"
        :typeCode="hardware.typeCode"
      />
    </div>
    <div class="step__block-count">
      <input
        type="number"
        v-model="qty"
        min="0"
        max="9999"
        step="1"
        :disabled="!canEditQty"
      />
    </div>
    <div class="step__block-count">
      <input
        type="number"
        :value="hardware.dimensions.area.getValue()"
        min="0"
        max="9999"
        step="1"
      />
    </div>
    <div>
      <PriceValue :price="hardware.price" class="step__block-price" />
    </div>
    <div>
      <PriceValue :price="hardware.sum" class="step__block-price" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineModel, watch, computed } from "vue";
import { PriceValue } from "@/shared";

import { MountHardware } from "@/app/domain/entities/hardware";
import { isMullions } from "@/app/predicates/mounts.predicates";

import MountHardwareValueSelect from "../MountHardwareValueSelect.vue";
import {
  MountsKompasFrameTypeCode,
  MountsKompasHorizontalTypeCode,
  MountsKompasVerticalTypeCode,
} from "@/app/constants/mounts";

import { unwrapRef } from "@/utils/reactive";
import { useMountsStore } from "@/stores/new/mounts.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { isKompasSystem } from "@/app/predicates/systems.predicates";
import { DimensionValue } from "@/app/domain/entities/dimensions";

type MountHardwareItemProps = {
  hardware: MountHardware;
};

const mountsStore = useMountsStore();
const estimateStore = useNewEstimateStore();

const activeMount = computed(() => mountsStore.activeMount);
const installedSystem = computed(
  () => estimateStore.estimate.installedSystemCode,
);

const structureType = defineModel<string>("structureType");
const qty = defineModel<number>("qty");
const props = defineProps<MountHardwareItemProps>();

const kompasHorizontalLine = computed(
  () =>
    activeMount.value?.getHardwareByStructureCode(
      MountsKompasHorizontalTypeCode,
    ),
);

const kompasVerticalLine = computed(
  () =>
    activeMount.value?.getHardwareByStructureCode(MountsKompasVerticalTypeCode),
);

const kompasPerimeterFrame = computed(
  () =>
    activeMount.value?.getHardwareByStructureCode(MountsKompasFrameTypeCode)
      ?.dimensions.area,
);

const canEditQty = computed(() =>
  isMullions(unwrapRef(props.hardware.typeCode)),
);

watch(
  () => [
    kompasHorizontalLine?.value?.dimensions.area,
    kompasVerticalLine?.value?.dimensions.area,
  ],
  ([horizontalArea, verticalArea]) => {
    if (!isKompasSystem(installedSystem.value)) return;

    if (!horizontalArea || !verticalArea || !kompasPerimeterFrame.value) return;

    const newArea =
      horizontalArea.getValue() +
      verticalArea.getValue() +
      kompasPerimeterFrame.value.getValue();

    props.hardware.dimensions.area = new DimensionValue(newArea);
  },
  { deep: true },
);
</script>

<style scoped lang="scss">
.step__block-price {
  font-weight: normal !important;
}

.step__block-wrapper {
  display: grid;
  row-gap: 5px;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
}

.step__block-count {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 15px;
}

.step__block-container {
  display: grid;
  grid-template-columns: 120px 280px 92px 92px repeat(2, 1fr);
  column-gap: 15px;
}

.step__block-wrapper--bottom .step__block-container {
  grid-template-columns: 180px 220px 72px 72px repeat(2, 1fr);
}

.step__block-container--top {
  margin-bottom: 10px;
}

.step__block-container div:first-child {
  align-items: flex-start;
}

.step__block-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.step__block-container div.step__block-div {
  align-items: center;
}

.step__block-container div input {
  text-align: center;
  padding: 0 10px;
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}

.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.select {
  margin-right: 20px;
}
</style>
