<template>
  <div class="step__block-table">
    <div class="step__block-item step__block-item--head">
      <div>
        <span>Name</span>
      </div>
      <div>
        <span>Q-ty</span>
      </div>
      <div>
        <span>Sum</span>
      </div>
    </div>
    <OverheadItem
      v-for="overheadItem of overheadItems"
      :key="overheadItem.structureType"
      :overhead-item="overheadItem"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { useOverheadStore } from "@/stores/new/overhead.store";

import OverheadItem from "./OverheadItem.vue";

const estimateStore = useNewEstimateStore();
const overheadStore = useOverheadStore();

const dealType = computed(() => estimateStore.estimate.dealTypeCode);
const overheadItems = computed(() => overheadStore.activeOveheadItems);
</script>

<style lang="scss" scoped>
.step__block-table {
  padding: 15px 0;
  display: grid;
  row-gap: 15px;
}

.step__block-item {
  display: grid;
  grid-template-columns: 1fr repeat(2, minmax(0, 55px));
  column-gap: 30px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-item--head {
  font-weight: 600;
}

.step__block-item-unit span {
  padding-right: 10px;
}

.step__block-item div:first-child {
  align-items: flex-start;
}

.step__block-item div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field.step__block-field--top {
  padding-top: 0;
  border-top: none;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}
</style>
