import { SystemType } from "../@types/shared";
import { OverheadItem } from "../domain/entities/overhead";
import { OverheadItemsStrategyRegistry } from "./overhead-strategies.service";

export class OverheadService {
    static createOverheadItems(
        areaFt: number,
        systemCode: string,
        hasInstallation: boolean,
        hasDelivery: boolean,
    ): OverheadItem[] {
        const overheadStrategiesRegisty = new OverheadItemsStrategyRegistry();

        const installationCode =
            overheadStrategiesRegisty.createInstallationKey(hasInstallation);
        const deliveryCode =
            overheadStrategiesRegisty.createDeliveryKey(hasDelivery);

        const Strategy = overheadStrategiesRegisty.getStrategy(
            systemCode as SystemType,
            installationCode,
            deliveryCode,
        );

        if (!Strategy) {
            return [];
        }

        const strategy = new Strategy({ areaFt });

        return strategy.create() as OverheadItem[];
    }
}
