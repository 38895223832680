import { Dictionary } from "@/models/dictionary";
import { useDictStore } from "@/stores/dictStore";

export class LineDividerCalculationService {
    /**
     * Calculates the number of horizontal dividers based on the system type and height.
     *
     * @param {string} systemType - The type of the system for which dividers are being calculated.
     * @param {number} height - The height for which the dividers need to be calculated.
     * @returns {number} - The number of horizontal dividers. Returns 0 if the system type is not found or an error occurs.
     *
     * @throws {Error} - Throws an error if the input validation fails.
     */
    static calculateHorizontalDividers(
        systemType: string,
        height: number,
    ): number {
        const dictStore = useDictStore();
        const dividers = dictStore.findHorizontalMullionsQty(height);

        console.group("calculateHorizontalDividers");
        console.log("systemType", systemType);
        console.log("height", height);
        console.log("dividers", dividers);
        console.groupEnd();

        return this.getDividersQtyBySystem(systemType, dividers);
    }

    /**
     * Calculates the number of vertical dividers based on the system type and width.
     *
     * @param systemType - The type of the system for which the dividers are being calculated.
     * @param width - The width of the area where the dividers will be placed.
     * @returns The number of vertical dividers, or 0 if the configuration is not found or the width is less than the minimum width.
     * @throws Will throw an error if the input validation fails.
     */
    static calculateVerticalDividers(
        systemType: string,
        width: number,
    ): number {
        const dictStore = useDictStore();
        const dividers = dictStore.findVerticalMullionsQty(width);

        return this.getDividersQtyBySystem(systemType, dividers);
    }

    static getDividersQtyBySystem(
        system: string,
        divider: Dictionary | undefined,
    ): number {
        const key = system.replace("cgf", "system");

        if (!divider) return 0;

        return divider[key] || 0;
    }
}
