<template>
  <div v-if="activeGlassDoor && activeGlassThinknessDoor">
    <div class="step__block-partitionglass">
      <GlassPriceTableRow>
        <div>
          <span>Glass sort:</span>
        </div>
        <GlassPriceTableCell>
          <span>Glass thickness:</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>Glass Sq.Ft:</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>Per sq.ft.</span>
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <span>SUM</span>
        </GlassPriceTableCell>
      </GlassPriceTableRow>
      <GlassPriceTableRow class="step__block-partitionglass-block--selects">
        <div>
          <GlassSortSelect v-model="activeGlassDoor.structureType" />
        </div>
        <GlassPriceTableCell>
          <DoorGlassThinknessSelect
            v-model="activeGlassThinknessDoor.structureType"
          />
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          {{ activeGlassDoor.dimensions.area.toSquareFt().format() }}
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <GlassPriceManager :pricing="activeGlassDoor.price" />
        </GlassPriceTableCell>
        <GlassPriceTableCell>
          <PriceValue :price="activeGlassDoor.sum" />
        </GlassPriceTableCell>
      </GlassPriceTableRow>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from "vue";

import {
  GlassPriceTableRow,
  GlassPriceTableCell,
  GlassSortSelect,
  PriceValue,
} from "@/shared";

import { GlassPriceManager } from "@/modules/glass";
import { DoorGlassThinknessSelect } from "@/modules/doors";

import { PricingService } from "@/app/services/pricing.service";
import { DimensionValue } from "@/app/domain/entities/dimensions";

import { useDoorsStore } from "@/stores/new/doors.store";

const doorsStore = useDoorsStore();

const pricingService = new PricingService();

const activeDoor = computed(() => doorsStore.activeDoor);

const activeGlassDoor = computed(() => doorsStore.activeGlassDoor);
const activeGlassThinknessDoor = computed(
  () => doorsStore.activeGlassThinknessDoor,
);

const calculatePrice = () => {
  const price = pricingService.getDoorGlassPrices();
  activeGlassDoor.value?.update({ price });
};

onMounted(() => {
  if (!activeGlassDoor.value?.price.isEmpty()) return;

  calculatePrice();
});

watch(
  () => [activeDoor.value?.dimensions.area, activeDoor.value?.qty],
  () => {
    if (!activeGlassDoor.value) return;

    const doorArea = activeDoor.value?.dimensions.area.getValue() || 0;
    const qty = activeDoor.value?.qty || 1;
    const doorGlassArea = doorArea * qty;

    activeGlassDoor.value.dimensions.area = new DimensionValue(doorGlassArea);
  },
  { immediate: true },
);

watch(
  () => [
    activeGlassDoor.value?.structureType,
    activeGlassThinknessDoor.value?.structureType,
  ],
  () => {
    calculatePrice();
  },
  { deep: true },
);

watch(
  () => [activeGlassDoor.value?.price, activeGlassDoor.value?.dimensions.area],
  () => {
    const sum = pricingService.calculateDoorGlassPrice();
    activeGlassDoor.value?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-partitionglass-subblock span {
  font-size: 14px !important;
  font-weight: normal !important;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
}
</style>
