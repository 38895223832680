import { createMountsTreviFrameConfig } from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createAnyDoorsMountsTreviFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);

    const qty = totalWidth * totalHeight;

    return createMountsTreviFrameConfig().qty(0).qty(qty).build();
};

export const TreviAnyDoorsConfigs = (partition: Partition) => [
    createAnyDoorsMountsTreviFrameConfig(partition),
];
