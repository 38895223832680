<template>
  <h2 class="step__left-title" v-if="activeStep">
    <span>Step {{ activeStep.index }} </span>
    {{ activeStep.name }}
    <span class="step__left-title-spase">{{ spaceTitle }}</span>
    <span>{{ partitionTitle }}</span>
  </h2>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStepsStore } from "@/stores/new/steps.store";
import { useSpacesStore } from "@/stores/new/spaces.store";
import { usePartitionStore } from "@/stores/new/partition.store";

const stepsStore = useStepsStore();
const spacesStore = useSpacesStore();
const partitionsStore = usePartitionStore();

const activeStep = computed(() => stepsStore.activeStep);
const spaceTitle = computed(() => spacesStore.activeSpace?.title);
const partitionTitle = computed(() => partitionsStore.activePartitions?.title);
</script>

<style scoped>
.step__left-title {
  color: #404d63;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.step__left-title span {
  font-weight: 400;
}

.step__left-title-spase {
  padding-right: 15px;
  padding-left: 10px;
}
</style>
