import {
    createMountsGlazingGasketConfig,
    createMountsKompasFrameConfig,
    createMountsKompasHorizontalConfig,
    createMountsKompasMullionsConfig,
    createMountsKompasVerticalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";

import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createSlidingDoorMountsKompasFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const perimeter = MountsService.getPertimetr(partition);
    const qty = 4;

    return createMountsKompasFrameConfig()
        .qty(qty)
        .width(perimeter)
        .area(perimeter)
        .build();
};

const createSlidingDoorMountsKompasHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);

    return createMountsKompasHorizontalConfig()
        .qty(1)
        .width(width)
        .area(width)
        .build();
};

const createSlidingDoorMountsKompasVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const height = MountsService.getTotalHeight(partition);
    const lines = MountsService.getKompasVerticalLines(partition);
    const length = MountsService.getKompasVerticalLinesLength(partition, lines);

    return createMountsKompasVerticalConfig()
        .qty(lines)
        .width(height)
        .area(length)
        .build();
};

const createSlidingDoorMountsKompasMullionsConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);

    return createMountsKompasMullionsConfig()
        .qty(0)
        .width(width)
        .area(0)
        .build();
};

const createSlidingDoorMountsKompasGlazingGasketsConfig = (
    partition: Partition,
): IComponentConfig => {
    const verticalLines = MountsService.getKompasVerticalLines(partition);
    const horizontalLines = MountsService.getKompasHorizontalLines(partition);

    const totalPerimetr = MountsService.getPertimetr(partition);
    const verticalLinesLength = MountsService.getKompasVerticalLinesLength(
        partition,
        verticalLines,
    );
    const horizontalLinesLength = MountsService.getKompasHorizontallLinesLength(
        partition,
        horizontalLines,
    );

    const total = totalPerimetr + verticalLinesLength + horizontalLinesLength;

    return createMountsGlazingGasketConfig()
        .qty(0)
        .width(total)
        .area(total)
        .build();
};

export const KompasSlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsKompasFrameConfig(partition),
    createSlidingDoorMountsKompasHorizontalConfig(partition),
    createSlidingDoorMountsKompasVerticalConfig(partition),
    createSlidingDoorMountsKompasGlazingGasketsConfig(partition),
    createSlidingDoorMountsKompasMullionsConfig(partition),
];
