import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { GlassPartition } from "../../entities/glass-partition";

export class GlassCalculationStrategy extends CalculationStrategy {
    calculate(component: ReactiveComponent): PriceResult {
        if (!component) {
            return this.getDefaultResult();
        }

        return {
            price: this.calculatePriceForType(component, CostDealType),
            priceB2C: this.calculatePriceForType(component, B2CDealType),
            priceB2B: this.calculatePriceForType(component, B2BDealType),
        };
    }

    private calculatePriceForType(component: GlassPartition, dealType: string) {
        const area = component.dimensions.area?.toSquareFt().getValue();
        const price = component.price.getSystemPrice(dealType).getValue();

        return price * area;
    }
}
