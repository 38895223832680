<template>
  <div v-if="partition" class="step__block-item">
    <div class="step__block-partitionglass-block">
      <div
        class="step__block-partitionglass-subblock step__block-partitionglass-subblock--first"
      >
        <span
          ><span class="space-title">{{ spaceTitle }}</span>
          {{ partitionTitle }}</span
        >
      </div>
      <div class="step__block-partitionglass-subblock">
        <input-num v-model="props.partition.qty" />
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>{{ partition.dimensions.area.toSquareFt().format() }}</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>{{ partition.price.getSystemPrice(dealTypeCode).format() }}</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>{{ partition.sum.getSystemPrice(dealTypeCode).format() }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import InputNum from "@/components/UI/InputNum.vue";

import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { PricingService } from "@/app/services/pricing.service";
import { Partition, Space } from "@/app/domain";

const estimateStore = useNewEstimateStore();

const pricingService = new PricingService();

const props = defineProps<{
  partition: Partition;
  space: Space;
}>();

const estimate = computed(() => estimateStore.estimate);
const dealTypeCode = computed(() => estimate.value.dealTypeCode);

const spaceTitle = computed(() => props.space?.title);
const partitionTitle = computed(() => props.partition?.title);

watch(
  () => [props.partition?.qty],
  () => {
    const sum = pricingService.calculatePartitionPrice(props.partition);
    props.partition?.update({ sum });
  },
  { immediate: true, deep: true },
);

watch(
  () => props.partition?.sum,
  () => {
    const sum = pricingService.calculateSpacePrice(props.space);
    props.space?.update({ sum });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.space-title {
  text-transform: uppercase;
}

.step__block-item {
  display: grid;
  row-gap: 15px;
  margin-bottom: 14px;
}

.step__block--data,
.step__block-wrapper {
  padding-right: 80px;
  display: grid;
  row-gap: 10px;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
  padding: 9px 20px;
  background-color: #fff;
  border-radius: 25px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-wrapper {
  margin-bottom: 15px;
}

.step__block-container {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-container-left {
  align-self: center;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
  display: flex;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-dimensions-right {
  display: flex;
  column-gap: 92px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-right div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step__block-dimensions-right div:last-child {
  width: 65px;
}

.step__block-dimensions {
  padding: 15px 0;
}

.step__block-dimensions-left {
  display: grid;
  grid-template-columns: minmax(0, 46px) repeat(4, 1fr);
  column-gap: 10px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-dimensions-units {
  display: grid;
  grid-template-columns: repeat(3, 60px);
  padding-bottom: 11px;
}

.step__block-dimensions-units div {
  padding-left: 11px;
}

.step__block-dimensions-container {
  display: grid;
  grid-template-columns: repeat(4, 60px);
}

.step__block-dimensions-container div {
  background-color: #fff;
  height: 35px;
}

.step__block-dimensions-container div input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 7px;
}

.step__block-dimensions-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step__block-dimensions-block--table:first-child
  .step__block-dimensions-container
  div {
  border-bottom: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table .step__block-dimensions-container div {
  border-right: 1px solid #d2d8e3;
}

.step__block-dimensions-block--table
  .step__block-dimensions-container
  div:last-child {
  border-right: none;
}

.step__block-partitionglass {
  padding: 15px 0;
}

.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 20% repeat(4, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  column-gap: 15px;
}

.step__block-partitionglass-block--selects {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
  font-size: 18px;
  font-weight: bold;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-partitionglass-total {
  display: grid;
  row-gap: 15px;
}

.step__block-dimensions-warning {
  align-self: flex-start;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #ff6255;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
}
</style>
