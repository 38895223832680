<template>
  <span>Installation:</span>
  <RadioGroup
    v-model="installation"
    name="installation"
    :yes-label="installationYesLabel"
    :disabled="!isInstallationPossible"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

import { useDictStore } from "@/stores/dictStore";
import RadioGroup from "@/shared/ui/RadioGroup.vue";

const dictStore = useDictStore();

const installation = defineModel<boolean>("installation");
const state = defineModel<string>("state");

const isInstallationPossible = ref(true);
const installationYesLabel = computed(() =>
  isInstallationPossible.value ? "Yes" : "Impossible",
);

watch(
  () => state.value,
  (newState) => {
    if (newState) {
      installation.value = dictStore.findState(newState)?.installation || false;
      isInstallationPossible.value =
        dictStore.findState(newState)?.installation || false;
    }
  },
);
</script>

<style scoped></style>
