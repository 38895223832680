import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsCGFTopFrameSortConfig,
    createMountsMullionsHorizontalConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF350Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const sides = 4;

    const qty = totalWidth * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF350Code,
            totalHeight,
        );

    const sides = 2;

    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

export const CGF350NoDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsCGFTopFrameSortConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
