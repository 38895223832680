import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
    createMountsCGFTopFrameSortConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsCGFTopFrameSortConfig = (
    space: Partition,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(space);

    return createMountsCGFTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    space: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    space: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const sides = 2;

    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    space: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const totalHeight = MountsService.getTotalHeight(space);
    const doorSections = MountsService.isForDoubleDoor(space) ? 3 : 2;
    const sides = 2;

    const sectionWidthFt = totalWidth / 2;
    const qty = (sectionWidthFt - doorSections) * sides * totalHeight;

    return createMountsMullionsVerticalConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    space: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(space);
    const doorSections = MountsService.isForDoubleDoor(space) ? 8 : 6;

    const qty = totalHeight * doorSections;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    space: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(space);
    const doorWidth = MountsService.getDoorTotalWidth(space);
    const sides = 2;

    const qty = (totalWidth - doorWidth) * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF400SwingDoorsConfigs = (space: Partition) => [
    createSlidingDoorMountsCGFTopFrameSortConfig(space),
    createSlidingDoorMountsCGFBottomFrameSortConfig(space),
    createSlidingDoorMountsMullionsHorizontalConfig(space),
    createSlidingDoorMountsMullionsVerticalConfig(space),
    createSlidingDoorMountsCGFFrameConfig(space),
    createSlidingDoorMountsGlazingGasketConfig(space),
];
