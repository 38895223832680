import { isRef } from "vue";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { unwrapRef } from "@/utils/reactive";

export class ExtraCalculationStrategy extends CalculationStrategy {
    calculate(component: ReactiveComponent): PriceResult {
        const extra = component.getParent();

        if (!extra) {
            return this.getDefaultResult();
        }

        return {
            price: this.calculatePriceForType(component, CostDealType),
            priceB2C: this.calculatePriceForType(component, B2BDealType),
            priceB2B: this.calculatePriceForType(component, B2CDealType),
        };
    }

    private calculatePriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        const qty = unwrapRef(component.qty);
        const price = component.price.getSystemPrice(dealType).getValue();

        return price * qty;
    }
}

export class ExtraTotalCalculationStrategy extends CalculationStrategy {
    calculate(component: ReactiveComponent): PriceResult {
        const partition = component?.getParent();

        if (!partition) {
            return this.getDefaultResult();
        }

        return {
            price: this.calculatePriceForType(component, CostDealType),
            priceB2C: this.calculatePriceForType(component, B2BDealType),
            priceB2B: this.calculatePriceForType(component, B2CDealType),
        };
    }

    private calculatePriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        const children = component.components;

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}
