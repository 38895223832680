import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(partition);
    return createMountsTopFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(partition);

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const sides = 4;

    const qty = totalWidth * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSlidingDoorMountsStripesConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getStipJointsWithoutDoorQty(partition);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200NoDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsTopFrameSortConfig(partition),
    createSlidingDoorMountsBottomFrameSortConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
    createSlidingDoorMountsStripesConfig(partition),
];
