import { createMountsRomanoFrameConfig } from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createAnyDoorsMountsRomanoFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);

    const qty = totalWidth * totalHeight;

    return createMountsRomanoFrameConfig().qty(0).area(qty).build();
};

export const RomanoAnyDoorsConfigs = (partition: Partition) => [
    createAnyDoorsMountsRomanoFrameConfig(partition),
];
