import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { Space } from "../../entities/space";

export class SpaceCalculationStrategy extends CalculationStrategy {
    public calculate(component: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                component,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                component,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                component,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(
        component: ReactiveComponent,
        dealType: string,
    ) {
        if (!component) return 0;

        const children = component.components;

        return children.reduce((total, child) => {
            const sum = child.sum.getSystemPrice(dealType).getValue();

            return total + sum;
        }, 0);
    }
}

export class SpacesTotalCalculationStrategy extends CalculationStrategy {
    public calculate(spaces: Space[]): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(spaces, CostDealType),
            priceB2C: this.calculateComponentsPriceForType(spaces, B2CDealType),
            priceB2B: this.calculateComponentsPriceForType(spaces, B2BDealType),
        };
    }

    private calculateComponentsPriceForType(spaces: Space[], dealType: string) {
        const subtotal = spaces.reduce((total, space) => {
            const partitionsSum = space
                .getAllPartitions()
                .reduce((partitionsTotalSum, partition) => {
                    return (
                        partitionsTotalSum +
                        partition.sum.getSystemPrice(dealType).getValue()
                    );
                }, 0);

            return total + partitionsSum;
        }, 0);

        return subtotal;
    }
}
