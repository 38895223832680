import { StructureItem } from "@/models/structure";
import {
    BaseTransformer,
    getPriceFromCalculationItem,
    getSumFromCalculationItem,
} from "./base.transformers";
import { Partition } from "../entities/partition";
import { unwrapRef } from "@/utils/reactive";
import { CalculationItem } from "@/models/calculate";
import {
    GlassPartition,
    GlassThinknessPartition,
} from "../entities/glass-partition";

type BaseGlassOldResponse = Pick<
    StructureItem,
    "glassSortCode" | "glassThicknessCode"
>;

interface BaseGlassTransformer<TNewResponse = GlassPartition>
    extends BaseTransformer<BaseGlassOldResponse, TNewResponse> {}

export const GlassTransformer: BaseGlassTransformer = {
    toOld(component: any) {
        const glass = component.getActiveGlass();
        const glassThickness = component.getActiveGlassThinkness();

        return {
            glassSortCode: glass?.structureType || "",
            glassThicknessCode: glassThickness?.structureType || "",
        };
    },
    fromOld(structure: StructureItem, calculations: CalculationItem[]) {
        const price = getPriceFromCalculationItem(
            calculations,
            "glassPartition",
        );

        const sum = getSumFromCalculationItem(calculations, "glassPartition");

        console.log("structure.dimensions", structure.dimensions);

        const glassPartition = new GlassPartition({
            structureType: structure.glassSortCode,
            price,
            sum,
            dimensions: {
                width: {
                    ft: 0,
                    inch: 0,
                    fractionDivisor: 0,
                    fractionDividend: 0,
                },
                height: {
                    ft: 0,
                    inch: 0,
                    fractionDivisor: 0,
                    fractionDividend: 0,
                },
                area:
                    parseFloat((structure.dimensions.areaFt as string) || "0") *
                    144,
            },
        });

        const glassThinkness = new GlassThinknessPartition({
            structureType: structure.glassThicknessCode,
        });

        glassPartition.addComponent(glassThinkness);

        return glassPartition;
    },
};
