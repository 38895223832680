<template>
  <PriceValue
    v-if="!isEditMode"
    :price="pricing"
    :disabled="!canChangePrice"
    @start:editing="isEditMode = true"
  />
  <GlassManualPriceInput
    v-if="isEditMode"
    :model-value="price"
    @update:model-value="onPriceChange"
    @stop:editing="isEditMode = false"
  />
  <p v-if="needChangePrice" class="error">Contact with estimator</p>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { Pricing } from "@/app/domain";
import { PriceValue } from "@/shared";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

import GlassManualPriceInput from "./GlassManualPriceInput.vue";
import { useUserStore } from "@/stores/userStore";

const estimateStore = useNewEstimateStore();
const userStore = useUserStore();

const dealType = computed(() => estimateStore.estimate.dealTypeCode);

const props = defineProps<{ pricing: Pricing }>();
const price = computed(() =>
  props.pricing.getSystemPrice(dealType.value).getValue(),
);

const needChangePrice = computed(
  () => price.value === 0 && userStore.isSalesManager,
);

const canChangePrice = computed(
  () => userStore.isSalesEstimator && price.value === 0,
);

const isEditMode = ref(false);

const onPriceChange = (newPrice: number) => {
  props.pricing.setPrice(newPrice);
};
</script>

<style scoped>
.step__block-partitionglass-subblock span {
  font-size: 14px !important;
  font-weight: normal !important;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 4px;
  text-align: right;
}
</style>
