import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { OverheadItem, Overheads } from "@/app/domain/entities/overhead";
import { OverheadService } from "@/app/services/overhead.service";

import { useSpacesStore } from "./spaces.store";
import { useNewEstimateStore } from "./estimateStore";

export const useOverheadStore = defineStore(
    "overhead-store",
    () => {
        const spacesStore = useSpacesStore();
        const estimateStore = useNewEstimateStore();

        const activeSpace = computed(() => spacesStore.activeSpace);
        const installedSystemCode = computed(
            () => estimateStore.estimate.installedSystemCode,
        );
        const installation = computed(
            () => estimateStore.estimate.installation,
        );
        const delivery = computed(() => estimateStore.estimate.delivery);

        const activeOverhead = ref<Overheads | null>(null);
        const activeOveheadItems = ref<OverheadItem[]>([]);

        const hasActiveOverhead = computed(() => activeOverhead.value !== null);

        const $reset = () => {
            activeOverhead.value = null;
            activeOveheadItems.value = [];
        };

        const $restoreOverheads = (overheadsFromApi: Overheads) => {
            activeOverhead.value = overheadsFromApi;
            activeOveheadItems.value = overheadsFromApi.getOverheadItems();
        };

        const addOverhead = () => {
            const overhead = new Overheads({});

            activeSpace.value?.replaceOverheads(overhead);
            activeOverhead.value = overhead;
        };

        const addOverheadItems = () => {
            if (!activeSpace.value) {
                return;
            }

            const overheadItems = OverheadService.createOverheadItems(
                activeSpace.value
                    .calculateTotalAreaSqFt()
                    .toSquareFt()
                    .getValue(),
                installedSystemCode.value,
                installation.value,
                delivery.value,
            );

            activeOverhead.value?.replaceOverheadItems(overheadItems);
            activeOveheadItems.value = overheadItems;
        };

        return {
            addOverhead,
            addOverheadItems,
            $reset,
            $restoreOverheads,
            activeOverhead,
            activeOveheadItems,
            hasActiveOverhead,
        };
    },
    { persist: true },
);
