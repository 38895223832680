import { AdditionalCostItem } from "@/models/structure";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { unwrapRef } from "@/utils/reactive";

import { BaseTransformer } from "./base.transformers";
import { OverheadItem, Overheads } from "../entities/overhead";

export const OverheadItemsTransformer: BaseTransformer<
    AdditionalCostItem[],
    Overheads
> = {
    toOld(overheads: Overheads) {
        if (!overheads) return [];

        const overheadItems = overheads.getOverheadItems();

        return overheadItems.map((overheadItem) => ({
            code: unwrapRef(overheadItem.code),
            costTypeCode: unwrapRef(overheadItem.typeCode),
            costValueCode: unwrapRef(overheadItem.structureType),
            title: unwrapRef(overheadItem.title),
            description: "",
            unit: "",
            qty: unwrapRef(overheadItem.qty),
            price: overheadItem.price.getSystemPrice(CostDealType).getValue(),
            priceB2C: overheadItem.price.getSystemPrice(B2CDealType).getValue(),
            priceB2B: overheadItem.price.getSystemPrice(B2BDealType).getValue(),
            sum: overheadItem.sum.getSystemPrice(CostDealType).getValue(),
        }));
    },
    fromOld(oldOverheadItems: AdditionalCostItem[]) {
        const overheads = new Overheads({});

        const overheadItems = oldOverheadItems.map(
            (overheadItem) =>
                new OverheadItem({
                    code: overheadItem.code,
                    typeCode: overheadItem.costTypeCode,
                    structureType: overheadItem.costValueCode,
                    title: overheadItem.title,
                    unit: overheadItem.unit,
                    qty: overheadItem.qty,
                    price: {
                        price: overheadItem.price,
                        priceB2C: overheadItem.priceB2C,
                        priceB2B: overheadItem.priceB2B,
                    },
                    sum: {
                        price: overheadItem.sum,
                        priceB2C: overheadItem.sum,
                        priceB2B: overheadItem.sum,
                    },
                }),
        );

        overheads.replaceOverheadItems(overheadItems);

        return overheads;
    },
};
