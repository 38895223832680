import { StructureItem } from "@/models/structure";
import { unwrapRef } from "@/utils/reactive";

import {
    BaseTransformer,
    convertStructureItemToReactiveComponent,
    getCalculationItem,
    getEmptyStructure,
} from "./base.transformers";

import { GlassTransformer } from "./glass.transformers";
import { Overdoor } from "../entities/overdoor";

export const OverdoorTransformer: BaseTransformer<StructureItem, Overdoor> = {
    toOld(overdoor: Overdoor) {
        if (!overdoor) return getEmptyStructure();

        const glass = overdoor.getActiveGlass();
        const glassThickness = overdoor.getActiveGlassThinkness();

        return {
            id: unwrapRef(overdoor.id) || "",
            code: unwrapRef(overdoor.code) || "",
            structureTypeCode: unwrapRef(overdoor.structureType) || "",
            title: unwrapRef(overdoor.title) || "",
            qty: unwrapRef(overdoor.qty) || 0,
            type: unwrapRef(overdoor.type) || "",
            dimensions: {
                width: {
                    ft: overdoor.dimensions.width.ft,
                    inch: overdoor.dimensions.width.inch,
                    fractionA: overdoor.dimensions.width.fractionDivisor,
                    fractionB: overdoor.dimensions.width.fractionDividend,
                },
                height: {
                    ft: overdoor.dimensions.height.ft,
                    inch: overdoor.dimensions.height.inch,
                    fractionA: overdoor.dimensions.height.fractionDivisor,
                    fractionB: overdoor.dimensions.height.fractionDividend,
                },
                perimeterFt: 0,
                areaFt: overdoor.dimensions.area.toSquareFt().format(),
            },
            glassSortCode: glass?.structureType || "",
            glassThicknessCode: glassThickness?.structureType || "",
            hardwares: [],
            note: "",
            outOfSquareQty: 0,
            additionalCosts: [],
            // filler specific
            fillerTypeCode: "", // glass , aluminum sheet, other
            fillerMaterialCode: "",
            fillerAssetsQty: 0,
        };
    },
    fromOld(overdoorConfig: StructureItem, calculations) {
        const calculationItem = getCalculationItem(
            calculations,
            overdoorConfig.code,
            overdoorConfig.type,
        );

        const overdoor = convertStructureItemToReactiveComponent(
            overdoorConfig,
            calculations,
            Overdoor,
        );

        const glass = GlassTransformer.fromOld(
            overdoorConfig,
            calculationItem?.subitems.items || [],
        );

        overdoor.addComponent(glass);

        return overdoor;
    },
};
