<template>
  <input type="text" v-model="zip" placeholder="" autocomplete="new-password" />
  <p class="error">{{ error }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { searchStateByZipCode } from "../api/zip.api";

const zip = defineModel<string>("zip");
const state = defineModel("state");

const error = ref<string | null>(null);

watch(
  () => zip.value,
  async (value) => {
    error.value = null;
    if (!value || value.length < 5) return;

    const foundedState = await searchStateByZipCode(value);

    if (!foundedState) {
      error.value = "State not found";
      return;
    }

    state.value = foundedState.state?.code;
  },
);
</script>

<style scoped>
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.step__block--data .step__block-container input,
.step__block-wrapper .step__block-container input {
  padding: 9px 20px;
  background-color: #fff;
  border-radius: 25px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
