import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsSideFrameSortConfig,
    createMountsStripesConfig,
    createMountsToptrackConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { Partition } from "@/app/domain/entities/partition";

const createMountsToptrackSlidingDoorHardwaresConfig = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(0)
        .area(1)
        .build();
};

const createMountsToptrackHardwaresConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTotalWidth(partition);

    return createMountsToptrackConfig().qty(0).area(qty).build();
};

const createMountsSideFrameSortHardwaresConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const sides = 2;

    const qty = totalHeight * sides;

    return createMountsSideFrameSortConfig().qty(0).area(qty).build();
};

const createMountsBottomFrameSortHardwaresConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const qty = totalWidth - doorWidth;

    return createMountsBottomFrameSortConfig().qty(0).area(qty).build();
};

const createMountsGlazingGasketHardwaresConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const hasTransom = MountsService.hasOverdoorTransom(partition);

    const sides = 2;

    const horizontalGaskets = totalWidth * sides;
    const doorDeduction = doorWidth - (hasTransom ? 0 : doorWidth);
    const verticalGaskets = totalHeight * sides - doorDeduction;

    const qty = (horizontalGaskets + verticalGaskets) * sides;

    return createMountsGlazingGasketConfig().qty(0).area(qty).build();
};

const createMountsStripesHardwaresConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(partition);

    return createMountsStripesConfig().qty(0).area(qty).build();
};

export const CGS200SlidingDoorsConfigs = (partition: Partition) => [
    createMountsToptrackHardwaresConfig(partition),
    createMountsToptrackSlidingDoorHardwaresConfig(partition),
    createMountsSideFrameSortHardwaresConfig(partition),
    createMountsBottomFrameSortHardwaresConfig(partition),
    createMountsGlazingGasketHardwaresConfig(partition),
    createMountsStripesHardwaresConfig(partition),
];
