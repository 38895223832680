<template>
  <StepAccordion
    v-for="block of blocks"
    :object-code="block.objectCode"
    :key="block.objectCode"
    :title="block.title"
  >
    <component
      :is="blockRenderers[block.type]"
      :block="block"
      @header-click-edit="emit('headerClickEdit', $event)"
      @header-click-delete="emit('headerClickDelete', $event)"
    />
  </StepAccordion>
</template>

<script setup lang="ts">
import { SummaryBlock as TSummaryBlock } from "@/models/summary";
import { StepAccordion } from "@/shared";

import InfoBlock from "./blocks/InfoBlock.vue";
import OverheadsBlock from "./blocks/OverheadsBlock.vue";
import DeliveryBlock from "./blocks/DeliveryBlock.vue";
import SpaceBlock from "./blocks/SpaceBlock.vue";

type SummaryBlockListProps = {
  blocks: TSummaryBlock[];
};

defineProps<SummaryBlockListProps>();
const emit = defineEmits(["headerClickEdit", "headerClickDelete"]);

const blockRenderers = {
  info: InfoBlock,
  overhead: OverheadsBlock,
  delivery: DeliveryBlock,
  space: SpaceBlock,
};
</script>

<style scoped></style>
