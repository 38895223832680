<template>
  <ul>
    <EstimateInfoLine
      v-for="line in lines"
      :key="line.code"
      :title="line.title"
      :value="line.value"
    />
  </ul>
</template>

<script setup lang="ts">
import { SummaryBlock as TSummaryBlock } from "@/models/summary";

import EstimateInfoLine from "../lines/EstimateInfoLine.vue";
import { computed } from "vue";

type InfoBlockProps = {
  block: TSummaryBlock;
};

const props = defineProps<InfoBlockProps>();
const lines = computed(() =>
  props.block.lines.filter((l) => l.type === "info"),
);
</script>

<style scoped></style>
