import { http } from "@/app/http/client";
import { State } from "../model/state";
import { AxiosResponse } from "axios";

type SearchResult = {
    zipСode: {
        code: string;
        title: string;
        applyTo: string[];
    };
    state: {
        code: string;
        title: string;
        applyTo: string[];
        installation: boolean;
    };
};

export const searchStateByZipCode = async (
    zip: string,
): Promise<SearchResult> => {
    const response = await http.get<SearchResult>(`/zip-codes/${zip}`);

    return response.data;
};
