<template>
  <select-dict
    v-model="overdoorType"
    :selectList="overdoorStructureTypes"
    :select-first-item="true"
    :disabled="!canUseTransom"
  />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue";

import SelectDict from "@/components/UI/SelectDict.vue";

import { useDictStore } from "@/stores/dictStore";
import { useDoorsStore } from "@/stores/new/doors.store";
import { usePartitionStore } from "@/stores/new/partition.store";
import { OverdoorNoneCode } from "@/app/constants";
import { useNewEstimateStore } from "@/stores/new/estimateStore";
import { isSteelSystem } from "@/app/predicates/systems.predicates";
import { useOverdoorStore } from "@/stores/new/overdoor.store";

const overdoorType = defineModel<string>();

const dictStore = useDictStore();
const doorStore = useDoorsStore();
const partitionStore = usePartitionStore();
const estimateStore = useNewEstimateStore();
const overdoorStore = useOverdoorStore();

const installedSystem = computed(
  () => estimateStore.estimate.installedSystemCode,
);

const activePartition = computed(() => partitionStore.activePartitions);
const activeDoor = computed(() => doorStore.activeDoor);

const doorType = computed(() => doorStore.activeDoor?.structureType || "");
const canUseTransom = computed(() => {
  if (isSteelSystem(installedSystem.value)) return true;

  return overdoorStore.canUseTransom(activePartition.value, activeDoor.value);
});

const overdoorStructureTypes = computed(() => {
  const types = dictStore.getStructureTypes(["filler", doorType.value]);

  const getOverdoorNone = () =>
    types.filter((type) => type.code === OverdoorNoneCode);

  if (activePartition.value?.isDivider()) {
    return getOverdoorNone();
  }

  if (!canUseTransom.value) {
    return getOverdoorNone();
  }

  return types;
});
</script>

<style lang="scss" scoped></style>
