import { Constructor, DoorType, MountType, SystemType } from "../@types/shared";
import {
    CGF300Code,
    CGF350Code,
    CGF400Code,
    CGS200Code,
    KompasDblCode,
    KompasFrameCode,
    KompasFramelessCode,
    KompasSglCode,
    MountPerimeterCode,
    MountTopBottomCode,
    NoDoors,
    NoMountCode,
    RomanoCode,
    SlidingDoors,
    SwingDoors,
    TreviCode,
} from "../constants";
import { ICreateManyStrategy } from "../domain/strategies/base/creation.strategy";
import {
    CGF300PerimeterSwingDoorHardwaresCreation,
    CGF300PerimeterSlidingDoorHardwaresCreation,
    CGF350PerimeterSwingDoorHardwaresCreation,
    CGF350PerimeterSlidingDoorHardwaresCreation,
    CGF400PerimeterSwingDoorHardwaresCreation,
    CGF400PerimeterSlidingDoorHardwaresCreation,
    CGS200PerimeterSwingDoorHardwaresCreation,
    CGS200PerimeterSlidingDoorHardwaresCreation,
    KompasPerimeterNoDoorHardwaresCreation,
    KompasPerimeterSlidingDoorHardwaresCreation,
    KompasPerimeterSwingDoorHardwaresCreation,
    CGF300TopbottomSwingDoorHardwaresCreation,
    CGF350TopbottomSwingDoorHardwaresCreation,
    CGF400TopbottomSwingDoorHardwaresCreation,
    CGS200TopbottomSwingDoorHardwaresCreation,
    RomanoNoMountAnyDoorHardwaresCreation,
    TreviNoMountAnyDoorHardwaresCreation,
    CGF300TopbottomSlidingDoorHardwaresCreation,
    CGF350TopbottomSlidingDoorHardwaresCreation,
    CGF400TopbottomSlidingDoorHardwaresCreation,
    CGS200TopbottomSlidingDoorHardwaresCreation,
    CGF300PerimeterNoDoorHardwaresCreation,
    CGF300TopbottomNoDoorHardwaresCreation,
    CGF350PerimeterNoDoorHardwaresCreation,
    CGF350TopbottomNoDoorHardwaresCreation,
    CGF400PerimeterNoDoorHardwaresCreation,
    CGF400TopbottomNoDoorHardwaresCreation,
    CGS200PerimeterNoDoorHardwaresCreation,
    CGS200TopbottomNoDoorHardwaresCreation,
    KompasCustomXLinesNoDoorHardwaresCreation,
    KompasCustomXLinesSlidingDoorHardwaresCreation,
    KompasCustomXLinesSwingDoorHardwaresCreation,
    KompasFramelessNoDoorHardwaresCreation,
    KompasFramelessSlidingDoorHardwaresCreation,
    KompasFramelessSwingDoorHardwaresCreation,
} from "../domain/strategies/creation/mount-hardwares.strategy";

export class MountHardwareStrategyRegistry {
    private strategies = new Map<string, Constructor<ICreateManyStrategy>>();

    // prettier-ignore
    constructor() {
        // Mount Perimeter Swing Doors
        this.register(CGF300Code, SwingDoors as DoorType[], MountPerimeterCode, CGF300PerimeterSwingDoorHardwaresCreation);
        this.register(CGF350Code, SwingDoors as DoorType[], MountPerimeterCode, CGF350PerimeterSwingDoorHardwaresCreation);
        this.register(CGF400Code, SwingDoors as DoorType[], MountPerimeterCode, CGF400PerimeterSwingDoorHardwaresCreation);
        this.register(CGS200Code, SwingDoors as DoorType[], MountPerimeterCode, CGS200PerimeterSwingDoorHardwaresCreation);
        this.register(KompasDblCode, SwingDoors as DoorType[], MountPerimeterCode, KompasPerimeterSwingDoorHardwaresCreation);
        this.register(KompasSglCode, SwingDoors as DoorType[], MountPerimeterCode, KompasPerimeterSwingDoorHardwaresCreation);

        // Mount Topbottom Swing Doors
        this.register(CGF300Code, SwingDoors as DoorType[], MountTopBottomCode, CGF300TopbottomSwingDoorHardwaresCreation);
        this.register(CGF350Code, SwingDoors as DoorType[], MountTopBottomCode, CGF350TopbottomSwingDoorHardwaresCreation);
        this.register(CGF400Code, SwingDoors as DoorType[], MountTopBottomCode, CGF400TopbottomSwingDoorHardwaresCreation);
        this.register(CGS200Code, SwingDoors as DoorType[], MountTopBottomCode, CGS200TopbottomSwingDoorHardwaresCreation);

        // Mount Perimeter Sliding Doors
        this.register(CGF300Code, SlidingDoors as DoorType[], MountPerimeterCode, CGF300PerimeterSlidingDoorHardwaresCreation);
        this.register(CGF350Code, SlidingDoors as DoorType[], MountPerimeterCode, CGF350PerimeterSlidingDoorHardwaresCreation);
        this.register(CGF400Code, SlidingDoors as DoorType[], MountPerimeterCode, CGF400PerimeterSlidingDoorHardwaresCreation);
        this.register(CGS200Code, SlidingDoors as DoorType[], MountPerimeterCode, CGS200PerimeterSlidingDoorHardwaresCreation);
        this.register(KompasDblCode, SlidingDoors as DoorType[], MountPerimeterCode, KompasPerimeterSlidingDoorHardwaresCreation);
        this.register(KompasSglCode, SlidingDoors as DoorType[], MountPerimeterCode, KompasPerimeterSlidingDoorHardwaresCreation);

        // Mount Topbottom Sliding Doors
        this.register(CGF300Code, SlidingDoors as DoorType[], MountTopBottomCode, CGF300TopbottomSlidingDoorHardwaresCreation);
        this.register(CGF350Code, SlidingDoors as DoorType[], MountTopBottomCode, CGF350TopbottomSlidingDoorHardwaresCreation);
        this.register(CGF400Code, SlidingDoors as DoorType[], MountTopBottomCode, CGF400TopbottomSlidingDoorHardwaresCreation);
        this.register(CGS200Code, SlidingDoors as DoorType[], MountTopBottomCode, CGS200TopbottomSlidingDoorHardwaresCreation);

        // Mount Perimeter No Doors
        this.register(CGF300Code, NoDoors as DoorType[], MountPerimeterCode, CGF300PerimeterNoDoorHardwaresCreation);
        this.register(CGF350Code, NoDoors as DoorType[], MountPerimeterCode, CGF350PerimeterNoDoorHardwaresCreation);
        this.register(CGF400Code, NoDoors as DoorType[], MountPerimeterCode, CGF400PerimeterNoDoorHardwaresCreation);
        this.register(CGS200Code, NoDoors as DoorType[], MountPerimeterCode, CGS200PerimeterNoDoorHardwaresCreation);
        this.register(KompasDblCode, NoDoors as DoorType[], MountPerimeterCode, KompasPerimeterNoDoorHardwaresCreation);
        this.register(KompasSglCode, NoDoors as DoorType[], MountPerimeterCode, KompasPerimeterNoDoorHardwaresCreation);

        // Mount Topbottom No Doors
        this.register(CGF300Code, NoDoors as DoorType[], MountTopBottomCode, CGF300TopbottomNoDoorHardwaresCreation);
        this.register(CGF350Code, NoDoors as DoorType[], MountTopBottomCode, CGF350TopbottomNoDoorHardwaresCreation);
        this.register(CGF400Code, NoDoors as DoorType[], MountTopBottomCode, CGF400TopbottomNoDoorHardwaresCreation);
        this.register(CGS200Code, NoDoors as DoorType[], MountTopBottomCode, CGS200TopbottomNoDoorHardwaresCreation);

        // No mount Swing Doors
        this.register(RomanoCode, SwingDoors as DoorType[], NoMountCode, RomanoNoMountAnyDoorHardwaresCreation);
        this.register(TreviCode, SwingDoors as DoorType[], NoMountCode, TreviNoMountAnyDoorHardwaresCreation);

        // Kompas Custom X Lines
        this.register(KompasDblCode, NoDoors as DoorType[], KompasFrameCode, KompasCustomXLinesNoDoorHardwaresCreation);
        this.register(KompasDblCode, SwingDoors as DoorType[], KompasFrameCode, KompasCustomXLinesSwingDoorHardwaresCreation);
        this.register(KompasDblCode, SlidingDoors as DoorType[], KompasFrameCode, KompasCustomXLinesSlidingDoorHardwaresCreation);

        this.register(KompasSglCode, NoDoors as DoorType[], KompasFrameCode, KompasCustomXLinesNoDoorHardwaresCreation);
        this.register(KompasSglCode, SwingDoors as DoorType[], KompasFrameCode, KompasCustomXLinesSwingDoorHardwaresCreation);
        this.register(KompasSglCode, SlidingDoors as DoorType[], KompasFrameCode, KompasCustomXLinesSlidingDoorHardwaresCreation);

        // Kompas Frameless
        this.register(KompasDblCode, NoDoors as DoorType[], KompasFramelessCode, KompasFramelessNoDoorHardwaresCreation);
        this.register(KompasDblCode, SwingDoors as DoorType[], KompasFramelessCode, KompasFramelessSwingDoorHardwaresCreation);
        this.register(KompasDblCode, SlidingDoors as DoorType[], KompasFramelessCode, KompasFramelessSlidingDoorHardwaresCreation);

        this.register(KompasSglCode, NoDoors as DoorType[], KompasFramelessCode, KompasFramelessNoDoorHardwaresCreation);
        this.register(KompasSglCode, SwingDoors as DoorType[], KompasFramelessCode, KompasFramelessSwingDoorHardwaresCreation);
        this.register(KompasSglCode, SlidingDoors as DoorType[], KompasFramelessCode, KompasFramelessSlidingDoorHardwaresCreation);
    }

    getStrategy = (
        systemCode: SystemType,
        doorCode: DoorType,
        mountType: MountType,
    ): Constructor<ICreateManyStrategy> | null => {
        const key = this.createKey(systemCode, doorCode, mountType);
        return this.strategies.get(key) || null;
    };

    private register = (
        systemCode: SystemType,
        doorTypes: DoorType[],
        mountType: MountType,
        strategy: Constructor<ICreateManyStrategy>,
    ) => {
        for (const doorType of doorTypes) {
            const key = this.createKey(systemCode, doorType, mountType);
            this.strategies.set(key, strategy);
        }
    };

    /**
     * Creates a unique key by concatenating the provided system code, door type, and mount type.
     *
     * @param systemCode - The code representing the system type.
     * @param doorType - The type of the door.
     * @param mountType - The type of the mount.
     * @returns A string that uniquely identifies the combination of the provided parameters.
     * Example: "cgf300:s-sw:mnt-perimeter".
     */
    private createKey(
        systemCode: SystemType,
        doorType: DoorType,
        mountType: MountType,
    ): string {
        return `${systemCode}:${doorType}:${mountType}`;
    }
}
