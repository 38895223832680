import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { ReactiveComponent } from "../../entities/base-component";
import { CalculationStrategy, PriceResult } from "../base/calculation.strategy";
import { OverheadItem } from "../../entities/overhead";
import { useDictStore } from "@/stores/dictStore";
import { unwrapRef } from "@/utils/reactive";
import { isKompasDlb } from "@/app/predicates/systems.predicates";
import { KompasDoubleGlazingPriceMultiplier } from "@/app/constants/multipicators";
import { OverheadPredicates, SystemPredicates } from "@/app/predicates";

export class OverheadCalculationStrategy extends CalculationStrategy {
    calculate = (
        overheadItem: ReactiveComponent,
        args: unknown[],
    ): PriceResult => {
        const [installedSystemCode] = args as [string];

        return {
            price: this.calculatePriceForType(
                overheadItem as OverheadItem,
                installedSystemCode,
            ),
            priceB2C: this.calculatePriceForType(
                overheadItem as OverheadItem,
                installedSystemCode,
            ),
            priceB2B: this.calculatePriceForType(
                overheadItem as OverheadItem,
                installedSystemCode,
            ),
        };
    };

    private calculatePriceForType(
        overheadItem: OverheadItem,
        installedSystemCode: string,
    ) {
        const structureCode = unwrapRef(overheadItem.structureType);
        const overheadSqFt = unwrapRef(overheadItem.qty);

        const multiplicator =
            SystemPredicates.isKompasDlb(installedSystemCode) &&
            OverheadPredicates.isCuttingMaterial(structureCode)
                ? KompasDoubleGlazingPriceMultiplier
                : 1;

        if (!overheadSqFt || overheadSqFt === 0) return 0;

        const overheadDict =
            useDictStore().findOverheadCostPrice(structureCode);

        const minSqFt = overheadDict.priceTo || 0;
        const minPrice = overheadDict.price || 0;
        const priceSqFt = overheadDict.priceSqFt || 0;

        if (overheadSqFt <= minSqFt) {
            return minPrice;
        }

        const additionalSqFtPrice = (overheadSqFt - minSqFt) * priceSqFt;

        return Math.round(minPrice + additionalSqFtPrice) * multiplicator;
    }
}
