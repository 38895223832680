import {
    createMountsGlazingGasketConfig,
    createMountsKompasCopolymerVerticalConfig,
    createMountsKompasFrameConfig,
    createMountsKompasHorizontalConfig,
    createMountsKompasMullionsConfig,
    createMountsKompasVerticalConfig,
    createMountsMullionsVerticalConfig,
} from "../../base";

import { IComponentConfig } from "@/app/@types/base-component";
import { Partition } from "@/app/domain/entities/partition";
import { MountsService } from "@/app/services/mounts.service";

const createNoDoorMountsKompasFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const perimeter = MountsService.getFramelessPertimetr(partition);
    const qty = 4;

    return createMountsKompasFrameConfig()
        .qty(qty)
        .width(perimeter)
        .area(perimeter)
        .build();
};

const createNoDoorMountsKompasHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);

    return createMountsKompasHorizontalConfig()
        .qty(1)
        .width(width)
        .area(width)
        .build();
};

const createNoDoorMountsKompasVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const height = MountsService.getTotalHeight(partition);
    const lines = MountsService.getKompasVerticalCopolymerLines(partition);
    const length = MountsService.getKompasVerticalCopolymerLinesLength(
        partition,
        lines,
    );

    return createMountsKompasCopolymerVerticalConfig()
        .qty(lines)
        .width(height)
        .area(length)
        .build();
};

const createNoDoorMountsKompasMullionsConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getTotalWidth(partition);

    return createMountsKompasMullionsConfig()
        .qty(0)
        .width(width)
        .area(0)
        .build();
};

const createNoDoorMountsKompasGlazingGasketsConfig = (
    partition: Partition,
): IComponentConfig => {
    const perimeter = MountsService.getFramelessPertimetr(partition);

    return createMountsGlazingGasketConfig()
        .qty(0)
        .width(perimeter)
        .area(perimeter)
        .build();
};

export const KompasNoDoorsConfigs = (partition: Partition) => [
    createNoDoorMountsKompasFrameConfig(partition),
    // createNoDoorMountsKompasHorizontalConfig(partition),
    createNoDoorMountsKompasVerticalConfig(partition),
    createNoDoorMountsKompasGlazingGasketsConfig(partition),
    createNoDoorMountsKompasMullionsConfig(partition),
];
