import { IComponentConfig } from "@/app/@types/base-component";
import {
    createMountsBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsStripesConfig,
    createMountsTopFrameSortConfig,
} from "../../base";
import { MountsService } from "@/app/services/mounts.service";
import { Partition } from "@/app/domain/entities/partition";

const createSwingDoorMountsTopFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getTopFrameWidth(partition);

    return createMountsTopFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getBottomFrameWidth(partition);

    return createMountsBottomFrameSortConfig().qty(qty).build();
};

const createSwingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const width = MountsService.getPartitionWidthWithoutDoor(partition);
    const height = MountsService.getTotalHeight(partition);

    const sides = 2;

    const horizontalGaskets = width * sides;
    const verticalGaskets = height * sides;

    const qty = horizontalGaskets + verticalGaskets * sides;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

const createSwingDoorMountsStripesConfig = (
    partition: Partition,
): IComponentConfig => {
    const qty = MountsService.getStipJointsQty(partition);

    return createMountsStripesConfig().qty(qty).build();
};

export const CGS200SwingDoorsConfigs = (partition: Partition) => [
    createSwingDoorMountsTopFrameSortConfig(partition),
    createSwingDoorMountsBottomFrameSortConfig(partition),
    createSwingDoorMountsGlazingGasketConfig(partition),
    createSwingDoorMountsStripesConfig(partition),
];
