<template>
  <StepTotalPrice
    v-if="activeDoor"
    title="Page totale"
    :price="activeDoor.sum"
  />
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import { StepTotalPrice } from "@/shared";
import { useDoorsStore } from "@/stores/new/doors.store";
import { PricingService } from "@/app/services/pricing.service";

const doorStore = useDoorsStore();
const pricingService = new PricingService();

const activeDoor = computed(() => doorStore.activeDoor);

watch(
  () => [activeDoor.value?.price, activeDoor.value?.qty],
  () => {
    if (!activeDoor.value) {
      return;
    }

    const sum = pricingService.calculateDoorPrice();

    activeDoor.value?.update({
      sum,
    });
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.step__block-field {
  display: flex;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
</style>
