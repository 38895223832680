import { MountMullionsTopbottom1ValueCode } from "@/app/constants/mounts";
import {
    createMountsCGFFrameConfig,
    createMountsCGFBottomFrameSortConfig,
    createMountsGlazingGasketConfig,
    createMountsMullionsBottomConfig,
    createMountsMullionsTopConfig,
    createMountsMullionsHorizontalConfig,
    createMountsMullionsVerticalConfig,
    createMountsToptrackConfig,
    createMountsToptrackSlidingDoorConfig,
} from "../../base";
import { IComponentConfig } from "@/app/@types/base-component";
import { MountsService } from "@/app/services/mounts.service";
import { LineDividerCalculationService } from "@/app/services/line-dividers.service";
import { CGF400Code } from "@/app/constants";
import { Partition } from "@/app/domain/entities/partition";

const createSlidingDoorMountsToptrackConfig = (
    partition: Partition,
): IComponentConfig => {
    const toptrackStuctureCode = MountsService.getTopTrack(partition);

    return createMountsToptrackSlidingDoorConfig()
        .structureType(toptrackStuctureCode)
        .qty(1)
        .build();
};

const createSlidingDoorMountsCGFBottomFrameSortConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const qty = totalWidth - doorWidth;

    return createMountsCGFBottomFrameSortConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsTopConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const sides = 2;

    const qty = totalWidth * sides;

    return createMountsMullionsTopConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsBottomConfig = (
    partition: Partition,
): IComponentConfig => {
    const doorWidth = MountsService.getDoorTotalWidth(partition);
    const sides = 2;

    const qty = doorWidth * sides;

    return createMountsMullionsBottomConfig()
        .structureType(MountMullionsTopbottom1ValueCode)
        .qty(qty)
        .build();
};

const createSlidingDoorMountsMullionsHorizontalConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const horizontalLinesQty =
        LineDividerCalculationService.calculateHorizontalDividers(
            CGF400Code,
            totalHeight,
        );

    const sides = 2;

    const qty = totalWidth * horizontalLinesQty * sides;

    return createMountsMullionsHorizontalConfig().qty(qty).build();
};

const createSlidingDoorMountsMullionsVerticalConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorSections = MountsService.isForDoubleDoor(partition) ? 3 : 2;
    const sides = 2;

    const sectionWidthFt = totalWidth / 2;
    const qty = (sectionWidthFt - doorSections) * sides * totalHeight;

    return createMountsMullionsVerticalConfig().qty(qty).build();
};

const createSlidingDoorMountsCGFFrameConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalHeight = MountsService.getTotalHeight(partition);
    const doorSections = MountsService.isForDoubleDoor(partition) ? 8 : 6;

    const qty = totalHeight * doorSections;

    return createMountsCGFFrameConfig().qty(qty).build();
};

const createSlidingDoorMountsGlazingGasketConfig = (
    partition: Partition,
): IComponentConfig => {
    const totalWidth = MountsService.getTotalWidth(partition);
    const doorWidth = MountsService.getDoorTotalWidth(partition);

    const sides = 2;

    const totalGaskets = totalWidth * sides;
    const doorGaskets = doorWidth * sides;

    const qty = totalGaskets - doorGaskets;

    return createMountsGlazingGasketConfig().qty(qty).build();
};

export const CGF400SlidingDoorsConfigs = (partition: Partition) => [
    createSlidingDoorMountsToptrackConfig(partition),
    createSlidingDoorMountsCGFBottomFrameSortConfig(partition),
    createSlidingDoorMountsMullionsTopConfig(partition),
    createSlidingDoorMountsMullionsBottomConfig(partition),
    createSlidingDoorMountsMullionsHorizontalConfig(partition),
    createSlidingDoorMountsMullionsVerticalConfig(partition),
    createSlidingDoorMountsCGFFrameConfig(partition),
    createSlidingDoorMountsGlazingGasketConfig(partition),
];
