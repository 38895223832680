import Fraction from "fraction.js";
import { isRef, ref, Ref, toRef } from "vue";

import * as Dimensions from "@/app/@types/dimensions";
import {
    fromFtToInches,
    fromInchesToFt,
    INCHES_PER_FOOT,
} from "@/utils/dimensions";

const DefaultDimensionConfig = {
    ft: 0,
    inch: 0,
    fractionDividend: 0,
    fractionDivisor: 0,
};

const DefaultReactiveComponentDimensionsConfig = {
    width: DefaultDimensionConfig,
    height: DefaultDimensionConfig,
    area: 0,
};

export class ReactiveDimensions implements Dimensions.IDimensions {
    ft: Ref<number>;
    inch: Ref<number>;
    fractionDividend: Ref<number>;
    fractionDivisor: Ref<number>;

    constructor(config: Dimensions.Config = DefaultDimensionConfig) {
        this.ft = ref(config.ft);
        this.inch = ref(config.inch);
        this.fractionDividend = ref(config.fractionDividend);
        this.fractionDivisor = ref(config.fractionDivisor);
    }

    /**
     * Updates the dimension values based on the provided configuration.
     *
     * @param {Dimensions.Config} config - The configuration object containing the new dimension values.
     * @param {number} config.ft - The feet value to update.
     * @param {number} config.inch - The inches value to update.
     * @param {number} config.fractionDividend - The fraction dividend value to update.
     * @param {number} config.fractionDivisor - The fraction divisor value to update.
     * @returns {void}
     */
    update = (config: Dimensions.Config): void => {
        this.ft.value = config.ft;
        this.inch.value = config.inch;
        this.fractionDividend.value = config.fractionDividend;
        this.fractionDivisor.value = config.fractionDivisor;
    };

    /**
     * Creates a copy of the current ReactiveDimensions instance.
     *
     * @returns {ReactiveDimensions} A new instance of ReactiveDimensions with the same values as the current instance.
     */
    copy = () => {
        return new ReactiveDimensions({
            ft: this.ft.value,
            inch: this.inch.value,
            fractionDividend: this.fractionDividend.value,
            fractionDivisor: this.fractionDivisor.value,
        });
    };

    /**
     * Subtracts the values of the target Dimensions object from the current Dimensions object.
     *
     * @param target - The Dimensions object to subtract from the current object.
     * @returns A new Dimensions object with the subtracted values.
     *
     *
     */
    substract = (target: Dimensions.IDimensions): Dimensions.IDimensions => {
        const result = this.copy();

        result.inch.value -= toRef(target.inch).value;
        result.ft.value -= toRef(target.ft).value;
        result.fractionDividend.value -= toRef(target.fractionDividend).value;
        result.fractionDivisor.value -= toRef(target.fractionDivisor).value;

        return result;
    };

    /**
     * Adds the values of the target dimensions to the current dimensions and returns the result.
     *
     * @param target - The dimensions to add to the current dimensions.
     * @returns A new Dimensions.IDimensions object with the summed values.
     */
    add = (target: Dimensions.IDimensions): Dimensions.IDimensions => {
        const result = this.copy();

        result.inch.value += toRef(target.inch).value;
        result.ft.value += toRef(target.ft).value;
        result.fractionDividend.value += toRef(target.fractionDividend).value;
        result.fractionDivisor.value += toRef(target.fractionDivisor).value;

        return result;
    };

    /**
     * Compares the current dimensions with the target dimensions.
     *
     * @param target - The target dimensions to compare with.
     * @returns The difference in length between the current dimensions and the target dimensions, in feet.
     *
     */
    compare = (target: Dimensions.IDimensions): number => {
        const diff = this.substract(target);

        return diff.getLength().toFt().getValue();
    };

    /**
     * Calculates the length in inches based on the current dimension values.
     *
     * @returns {Dimensions.IDimensionValue} The calculated length as a DimensionValue object.
     *
     * @remarks
     * This method prevents division by zero by returning a DimensionValue of current FT in inches if the fraction divisor is zero.
     * It converts feet to inches, adds the inches part, and then combines it with the fractional inches.
     */
    getLength = (): Dimensions.IDimensionValue => {
        if (this.fractionDivisor.value === 0) {
            return new DimensionValue(fromFtToInches(this.ft.value));
        }

        const ftInInches = fromFtToInches(this.ft.value);
        const inches = new Fraction(
            this.fractionDividend.value,
            this.fractionDivisor.value,
        )
            .add(ftInInches + this.inch.value)
            .valueOf();

        return new DimensionValue(inches);
    };

    /**
     * Retrieves the dimension values.
     *
     * @returns {Dimensions.Config} An object containing the dimension values:
     * - `ft`: The feet value.
     * - `inch`: The inches value.
     * - `fractionDividend`: The numerator of the fractional part.
     * - `fractionDivisor`: The denominator of the fractional part.
     */
    getValues = (): Dimensions.Config => {
        return {
            ft: this.ft.value,
            inch: this.inch.value,
            fractionDividend: this.fractionDividend.value,
            fractionDivisor: this.fractionDivisor.value,
        };
    };

    /**
     * Checks if the dimension values are all zero.
     *
     * @returns {boolean} True if all dimension values (ft, inch, fractionDividend, fractionDivisor) are zero, otherwise false.
     */
    isEmpty(): boolean {
        const ft = isRef(this.ft) ? this.ft.value : this.ft;
        const inch = isRef(this.inch) ? this.inch.value : this.inch;
        const fractionDividend = isRef(this.fractionDividend)
            ? this.fractionDividend.value
            : this.fractionDividend;
        const fractionDivisor = isRef(this.fractionDivisor)
            ? this.fractionDivisor.value
            : this.fractionDivisor;

        return (
            ft === 0 &&
            inch === 0 &&
            fractionDividend === 0 &&
            fractionDivisor === 0
        );
    }
}

export class ReactiveComponentDimensions
    implements Dimensions.ComponentDimensions
{
    width: Dimensions.IDimensions;
    height: Dimensions.IDimensions;
    area: Dimensions.IDimensionValue;

    constructor(
        config: Dimensions.ReactiveComponentConfig = DefaultReactiveComponentDimensionsConfig,
    ) {
        this.width = new ReactiveDimensions(config.width);
        this.height = new ReactiveDimensions(config.height);

        this.area = config.area
            ? new DimensionValue(config.area)
            : this.calculateArea();
    }

    /**
     * Calculates the area in square feet based on the width and height dimensions.
     *
     * @returns {Dimensions.IDimensionValue} The area in square feet as a DimensionValue object.
     */

    getAreaFt = (): Dimensions.IDimensionValue => {
        return this.area;
    };

    /**
     * Creates a deep copy of the current `Dimensions.ComponentDimensions` instance.
     *
     * @returns {Dimensions.ComponentDimensions} A new instance of `ReactiveComponentDimensions` with copied width and height.
     */
    copy = (): Dimensions.ComponentDimensions => {
        return new ReactiveComponentDimensions({
            width: this.width.getValues(),
            height: this.height.getValues(),
            area: this.area.getValue(),
        });
    };

    /**
     * Serializes the dimensions into a reactive component configuration object.
     *
     * @returns {Dimensions.ReactiveComponentConfig} The serialized configuration object containing the width and height values.
     */
    serialize(): Dimensions.ReactiveComponentConfig {
        return {
            width: this.width.getValues(),
            height: this.height.getValues(),
            area: this.area.getValue(),
        };
    }

    /**
     * Deserializes the given configuration object and updates the dimensions.
     *
     * @param config - The configuration object containing the width and height to update.
     * @returns The updated component dimensions.
     */
    deserialize(
        config: Dimensions.ReactiveComponentConfig,
    ): Dimensions.ComponentDimensions {
        console.group("deserialize");

        this.width.update(config.width);
        this.height.update(config.height);
        this.area = new DimensionValue(config.area);

        console.groupEnd();

        return this;
    }

    /**
     * Checks if both the width and height dimensions are empty.
     *
     * @returns {boolean} True if both width and height are empty, otherwise false.
     */
    isEmpty(): boolean {
        return this.width.isEmpty() && this.height.isEmpty();
    }

    /**
     * Checks if the area is empty.
     *
     * This method converts the area to square feet and checks if the value is zero.
     *
     * @returns {boolean} `true` if the area is zero square feet, otherwise `false`.
     */
    isAreaEmpty(): boolean {
        return this.area.toSquareFt().getValue() === 0;
    }

    /**
     * Calculates the area based on the width and height dimensions.
     * Converts the width and height from their current units to inches,
     * then computes the area in square feet.
     * The computed area is stored in the `area` property as a `DimensionValue` object.
     *
     * @throws {Error} If the width or height dimensions are not properly defined.
     */

    calculateArea(): Dimensions.IDimensionValue {
        const widthInch = this.width.getLength().toInches().getValue();
        const heightInch = this.height.getLength().toInches().getValue();

        const areaInFt = widthInch * heightInch;

        return new DimensionValue(areaInFt);
    }

    /**
     * Subtracts the given area from the current area of the dimension.
     *
     * @param area - The area to be subtracted, represented as an object implementing the `Dimensions.IDimensionValue` interface.
     *
     * This method calculates the new area by converting both the current area and the target area to square feet,
     * subtracting the target area from the current area, and then updating the dimension's area with the result.
     */
    substractArea(
        dimensions: Dimensions.ComponentDimensions,
    ): Dimensions.IDimensionValue {
        const sourceDimensions = this.copy();

        const sourceArea = sourceDimensions.calculateArea();
        const targetArea = dimensions.calculateArea();

        const newArea =
            sourceArea.toInches().getValue() - targetArea.toInches().getValue();

        return new DimensionValue(newArea);
    }
}

export class DimensionValue implements Dimensions.IDimensionValue {
    private result: number = 0;
    private decimalPart = 1;

    constructor(private value: number) {
        this.result = value;
    }

    /**
     * Converts the current dimension value from inches to feet.
     *
     * @returns {Dimensions.IDimensionValue} The dimension value in feet.
     */

    toFt(): Dimensions.ValueResult {
        const ft = fromInchesToFt(this.value);

        return {
            getValue: () => ft,
            format: () => this.format(ft),
            formatSq: () => this.formatSq(ft),
        };
    }

    /**
     * Converts the current dimension value from square inches to square feet.
     *
     * @returns {Dimensions.IDimensionValue} The dimension value in square feet.
     */
    toSquareFt(): Dimensions.ValueResult {
        const squareFt = this.value / Math.pow(INCHES_PER_FOOT, 2);

        return {
            getValue: () => squareFt,
            format: () => this.format(squareFt),
            formatSq: () => this.formatSq(squareFt),
        };
    }

    /**
     * Converts the current dimension value to inches.
     *
     * @returns {Dimensions.IDimensionValue} The dimension value in inches.
     */
    toInches(): Dimensions.ValueResult {
        return {
            getValue: () => this.value,
            format: () => this.format(this.value),
            formatSq: () => this.formatSq(this.value),
        };
    }

    /**
     * Retrieves the calculated result.
     *
     * @returns {number} The result value.
     */
    getValue(): number {
        return this.result;
    }

    /**
     * Formats the result as a string with a specified number of decimal places.
     * If the result is not available, returns "0.00".
     *
     * @returns {string} The formatted result as a string.
     */
    private format(value: number): string {
        if (!value) return "0.0";
        return Intl.NumberFormat("en", {
            style: "decimal",
            minimumFractionDigits: this.decimalPart,
            maximumFractionDigits: this.decimalPart,
        }).format(value);
    }

    private formatSq(value: number): string {
        if (!value) return "0.0";
        return Math.round(value).toString();
    }
}
