export const MountsCode = "mounts";

// Type codes

export const MountsTopFrameSortTypeCode = "mnt-top";
export const MountsSideFrameSortTypeCode = "mnt-side";
export const MountsBottomFrameSortTypeCode = "mnt-bottom";
export const MountsStripesTypeCode = "mnt-stripes";
export const Mounts3MTapeTypeCode = "mnt-tape";
export const MountsMullionsTopTypeCode = "mnt-mul-top";
export const MountsMullionsBottomTypeCode = "mnt-mul-btm";
export const MountsMullionsMiddleTypeCode = "mnt-mul-mdl";
export const MountsMullionsHorizontalTypeCode = "mnt-mul-hrz";
export const MountsMullionsVerticalTypeCode = "mnt-mul-vrt";
export const MountsToptrackTypeCode = "mnt-toptrack";
export const MountsToptrackSlidingDoorTypeCode = "mnt-toptrack-sl";
export const MountsCGFFrameTypeCode = "mnt-cgf-frame";
export const MountsToptrackEndTypeCode = "mnt-toptrack-end";
export const MountsGlazingGasketTypeCode = "mnt-gasket";
export const MountsKompasHorizontalTypeCode = "mnt-kmp-prfh";
export const MountsKompasVerticalTypeCode = "mnt-kmp-prfv";
export const MountsKompasFrameTypeCode = "mnt-kmp-tot";
export const MountsKompasMullionsTypeCode = "mnt-mul";
export const MountsKompasCopolymerVerticalTypeCode = "mnt-ver-cop";

export const MountsTreviFrameTypeCode = "mnt-trevi-frame";
export const MountsRomanoFrameTypeCode = "mnt-romano-frame";
export const MountsCGSFrameTypeCode = "mnt-cgs-frame";
export const MountsCGFTopFrameSortTypeCode = "mnt-cgf-frame-top";
export const MountsCGFSideFrameSortTypeCode = "mnt-cgf-frame-side";
export const MountsCGFBottomFrameSortTypeCode = "mnt-cgf-frame-btm";

export const MountsToptrackNoholderTypeCode = "mnt-toptrack-noholder";
export const MountsToptrackNoholderValueCode = "mnt-toptrack-noholder-1";

// Value codes
// export const MountsToptrackSlidingValueCode = "mnt-toptrack-sl-";
// export const MountsToptrackEndValueCode = "mnt-toptrack-end-1";
// export const MountsToptack1ValueCode = "mnt-toptrack-1";
export const MountUchDeep1_12ValueCode = "mnt-uch-deep-1-12";
export const MountUchLow1_2ValueCode = "mnt-uch-low-1-2";
export const MountGlazingGasket1ValueCode = "mnt-gsk-1";
export const MountStripes1ValueCode = "mnt-strp-1";
export const MountUchCGF1ValueCode = "mnt-uch-cgf1";
export const MountCGF1MullionsValueCode = "mnt-mul-topbtm-cgf1";
export const MountMullionsTopbottom1ValueCode = "mnt-mul-topbtm-1";
export const MountCGF1FrameValueCode = "mnt-cgf-frame-1";
export const MountTape1ValueCode = "mnt-tape-1";
export const MountKomapsAluminiumProfileValueCode = "mnt-kmp-prfv-1";
export const MountKomapsFrameValueCode = "mnt-kmp-prfv-2";
export const MountKomapsCopolymerValueCode = "mnt-ver-cop-1";
export const MountTreviSteelFrameValueCode = "mnt-frame-sq-trevi";
export const MountRomanoSteelFrameValueCode = "mnt-frame-sq-romano";

export const MountsToptrackSliding1ValueCode = "track_4";
export const MountsToptrackSliding2ValueCode = "track_5";
export const MountsToptrackSliding3ValueCode = "track_6";
export const MountsToptrackSliding4ValueCode = "track_7";
export const MountsToptrackSliding5ValueCode = "track_8";
export const MountsToptrackSliding6ValueCode = "track_9";
export const MountsToptrackSliding7ValueCode = "track_10";
export const MountsToptrackSliding8ValueCode = "track_12";
export const MountsToptrackSliding9ValueCode = "track_13";
export const MountsToptrackSliding10ValueCode = "track_14";

export const MountMullionsCodes = [
    MountsMullionsTopTypeCode,
    MountsMullionsBottomTypeCode,
    MountsMullionsMiddleTypeCode,
    MountsMullionsHorizontalTypeCode,
    MountsMullionsVerticalTypeCode,
];
