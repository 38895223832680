import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";

import { PricingStrategy, PriceResult } from "../base/pricing.strategy";
import { ReactiveComponent } from "../../entities/base-component";
import { Partition } from "../../entities/partition";

export class PartitionPagePricingStrategy extends PricingStrategy {
    public getPrices(component: ReactiveComponent): PriceResult {
        return {
            price: this.calculateComponentsPriceForType(
                component as Partition,
                CostDealType,
            ),
            priceB2C: this.calculateComponentsPriceForType(
                component as Partition,
                B2CDealType,
            ),
            priceB2B: this.calculateComponentsPriceForType(
                component as Partition,
                B2BDealType,
            ),
        };
    }

    private calculateComponentsPriceForType(
        partition: Partition,
        dealType: string,
    ) {
        if (!partition) return 0;

        const glass = partition.getActiveGlass();
        const outOfSquare = partition.getActiveOutOfSquare();

        const glassPrice = glass?.sum.getSystemPrice(dealType).getValue() || 0;

        const outOfSquarePrice =
            outOfSquare?.sum.getSystemPrice(dealType).getValue() || 0;

        return glassPrice + outOfSquarePrice;
    }
}
