import { computed, watch } from "vue";

import { usePartitionStore } from "@/stores/new/partition.store";

import { PricingService } from "../services/pricing.service";

export const usePartitionPrice = () => {
    const pricingService = new PricingService();

    const partitionsStore = usePartitionStore();

    const partition = computed(() => partitionsStore.activePartitions);

    watch(
        () => partition.value?.components,
        () => {
            const updatedPartitionPrices = pricingService.getPartitionPrices();
            partition.value?.update({ price: updatedPartitionPrices });
        },
        { deep: true, immediate: true },
    );

    watch(
        () => partition.value?.price,
        () => {
            const updatedPartitionSum =
                pricingService.calculatePartitionPrice();

            partition.value?.update({ sum: updatedPartitionSum });
        },
        { deep: true },
    );
};
