<template>
  <component :is="activeStep?.component" ref="componentRef" />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStepsStore } from "@/stores/new/steps.store";

const stepsStore = useStepsStore();
const activeStep = computed(() => stepsStore.activeStep);

const componentRef = ref<any>(null);

watch(
  componentRef,
  (newComponent) => {
    stepsStore.setActiveStepComponent(newComponent);
  },
  { immediate: true, deep: true },
);
</script>

<style scoped></style>
