<template>
  <div class="step__block-partitionglass">
    <div class="step__block-partitionglass-block">
      <div></div>
      <div class="step__block-partitionglass-subblock">
        <span>Q-ty</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>Size, sq.tf</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>{{ dealTypeCode }}</span>
      </div>
      <div class="step__block-partitionglass-subblock">
        <span>SUM</span>
      </div>
    </div>
    <template v-for="space of spaces" :key="space.id">
      <PartitionPriceTable
        v-for="partition of space.getAllPartitions()"
        :key="partition.id.value"
        :space="space"
        :partition="partition"
      />
    </template>
  </div>

  <SpacesPriceTotal />
</template>

<script setup lang="ts">
import { computed, defineExpose } from "vue";

import { PartitionPriceTable } from "@/modules/partition";
import { SpacesPriceTotal } from "@/modules/spaces";

import { useSpacesStore } from "@/stores/new/spaces.store";
import { useNewEstimateStore } from "@/stores/new/estimateStore";

const spacesStore = useSpacesStore();
const estimateStore = useNewEstimateStore();

const estimate = computed(() => estimateStore.estimate);
const spaces = computed(() => spacesStore.spaces);

const dealTypeCode = computed(() => estimate.value.dealTypeCode);

const validatedPage = () => {
  return true;
};

defineExpose({
  validatedPage,
});
</script>

<style scoped>
.step__block-partitionglass-block {
  display: grid;
  grid-template-columns: 20% repeat(4, 1fr);
  column-gap: 15px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  column-gap: 15px;

  margin-bottom: 14px;
}

.step__block-partitionglass-subblock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.step__block-partitionglass-subblock--first {
  align-items: flex-start;
  font-size: 18px;
  font-weight: bold;
}

.step__block-partitionglass-subblock--unit {
  padding-right: 10px;
}

.step__block-partitionglass-subblock input {
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 10px;
  text-align: center;
}

.step__block-wrapper {
  display: grid;
  row-gap: 5px;
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
}

.step__block-count {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  column-gap: 15px;
}

.step__block-container {
  display: grid;
  grid-template-columns: 120px 280px 92px repeat(2, 1fr);
  column-gap: 15px;
}

.step__block-wrapper--bottom .step__block-container {
  grid-template-columns: 180px 220px 72px repeat(2, 1fr);
}

.step__block-container--top {
  margin-bottom: 10px;
}

.step__block-container div:first-child {
  align-items: flex-start;
}

.step__block-container div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.step__block-container div.step__block-div {
  align-items: center;
}

.step__block-container div input {
  text-align: center;
  padding: 0 10px;
  width: 70px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.step__block-field {
  padding: 15px 0;
  border-top: 1px solid #d2d8e3;
  border-bottom: 1px solid #d2d8e3;
}

.step__block-field h3 {
  color: #404d63;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-price {
  color: #404d63;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.step__block-field-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.step__block-field--hardware .step__block-field-block {
  justify-content: flex-start;
  column-gap: 10px;
}

.step__block-doortype {
  display: grid;
  grid-template-columns: minmax(0, max-content) 316px;
  column-gap: 26px;
  color: #404d63;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.step__block-doortype--transform {
  padding-top: 15px;
}

.step__block-doortype div:first-child {
  align-self: center;
  justify-self: center;
}

.select {
  margin-right: 20px;
}
</style>
