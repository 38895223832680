import { HardwareItem } from "@/models/structure";
import {
    BaseTransformer,
    getPriceFromCalculationItem,
    getSumFromCalculationItem,
} from "./base.transformers";
import { unwrapRef } from "@/utils/reactive";
import { Hardware, MountHardware } from "../entities/hardware";
import { B2BDealType, B2CDealType, CostDealType } from "@/app/constants";
import { getFromDictByCode, useDictStore } from "@/stores/dictStore";
import { MountsCode } from "@/app/constants/mounts";

const getHardwareItemTitle = (hardwareTypeCode: string): string => {
    const dictStore = useDictStore();

    const title = getFromDictByCode(
        dictStore.getHardwareTypes(MountsCode),
        hardwareTypeCode,
    )?.title;

    if (!title) return hardwareTypeCode;

    // Replace title for mounts CGF systems
    return title.replace("CGF ", "");
};

export const MountHardwareTransformer: BaseTransformer<
    HardwareItem,
    MountHardware
> = {
    toOld(hardware: MountHardware) {
        return {
            code: unwrapRef(hardware.code) || "",
            hardwareTypeCode: unwrapRef(hardware.typeCode) || "",
            hardwareValueCode: unwrapRef(hardware.structureType) || "",
            type: unwrapRef(hardware.type) || "",
            qty: unwrapRef(hardware.qty) || 0,
            price: hardware.price.getSystemPrice(CostDealType).getValue() || 0,
            priceB2C:
                hardware.price.getSystemPrice(B2CDealType).getValue() || 0,
            priceB2B:
                hardware.price.getSystemPrice(B2BDealType).getValue() || 0,
            sum: hardware.sum.getSystemPrice(B2CDealType).getValue() || 0,
        };
    },
    fromOld(hardwareConfig: HardwareItem, calculations) {
        const price = getPriceFromCalculationItem(
            calculations,
            "mount-hardware",
            hardwareConfig.code,
        );

        const sum = getSumFromCalculationItem(
            calculations,
            "mount-hardware",
            hardwareConfig.code,
        );

        return new MountHardware({
            title: getHardwareItemTitle(hardwareConfig.hardwareTypeCode),
            code: hardwareConfig.code,
            typeCode: hardwareConfig.hardwareTypeCode,
            structureType: hardwareConfig.hardwareValueCode,
            qty: hardwareConfig.qty,
            price,
            sum,
        });
    },
};
