import { defineStore } from "pinia";

import { computed, ref } from "vue";
import dayjs from "dayjs";

import { Estimate, Pricing } from "@/app/domain";
import { generateCode } from "@/utils/common";
import { getTitleFromDictByCode, useDictStore } from "../dictStore";
import { getEstimate, postEstimate } from "@/utils/api";
import { useUserStore } from "../userStore";
import { usePriceStore } from "../priceStore";
import { SummaryService } from "@/services/summaryService";
import { useDeliveryStore } from "./delivery.store";
import { useSpacesStore } from "./spaces.store";
import { EstimateConverterService } from "@/app/services/legasy-estimate-converter.service";
import { DimensionValue } from "@/app/domain/entities/dimensions";

const LOCAL_STORAGE_ESTIMATE_KEY = "estimate";

export const useNewEstimateStore = defineStore(
    "new-estimate",
    () => {
        const dictStore = useDictStore();
        const userStore = useUserStore();
        const deliveryStore = useDeliveryStore();
        const spacesStore = useSpacesStore();

        const estimate = ref<Estimate>({
            title: "",
            started: new Date(),
            code: generateCode(),
            priceDate: Date.now(),
            priceDateFormated: dayjs().format("YYYY-MM-DD"),
            estimateNo: dayjs().format("YYMMDD-hm"),
            trackCode: "",
            dealTypeCode: "",
            installationPlaceCode: "",
            installationUsageCode: "",
            installedSystemCode: "",
            colorCode: "",
            delivery: true,
            installation: false,
            shippingZip: "",
            shippingState: "",
            estimatorCode: "",
            managerCode: "",
            multiplicator: 1,
            client: {
                name: "",
                businessName: "",
                email: "",
                phone: "",
                shippingAddress: "",
            },
            progress: {
                status: "DRAFT",
                updated: new Date(),
                validatedPage: 0,
                activePage: 1,
                activeSpaceCode: "",
                activeStructureCode: "",
                activeMountsCode: "",
                activeFillerCode: "",
                activeDoorCode: "",
                isSaved: false,
            },
            discountPercent: 10,
            discountSum: 0,
            subtotal: new Pricing(),
            estimatePrice: new Pricing(),
            halfInstallation: 0,
            halfInstallationWithDiscount: 0,
            isHalfInstallation: true,
            totalSquareFt: null,
        });

        const activeSpace = computed(() => spacesStore.activeSpace);
        const spaces = computed(() => spacesStore.spaces);
        const profile = computed(() => userStore.profile);

        const $reset = () => {
            estimate.value = {
                title: "",
                started: new Date(),
                code: generateCode(),
                priceDate: Date.now(),
                priceDateFormated: dayjs().format("YYYY-MM-DD"),
                estimateNo: dayjs().format("YYMMDD-hm"),
                trackCode: "",
                dealTypeCode: "",
                installationPlaceCode: "",
                installationUsageCode: "",
                installedSystemCode: "",
                colorCode: "bl",
                delivery: true,
                installation: false,
                shippingZip: "",
                shippingState: "",
                estimatorCode: "",
                managerCode: "",
                multiplicator: 1,
                client: {
                    name: "",
                    businessName: "",
                    email: "",
                    phone: "",
                    shippingAddress: "",
                },
                progress: {
                    status: "DRAFT",
                    updated: new Date(),
                    validatedPage: 0,
                    activePage: 1,
                    activeSpaceCode: "",
                    activeStructureCode: "",
                    activeMountsCode: "",
                    activeFillerCode: "",
                    activeDoorCode: "",
                    isSaved: false,
                },
                discountPercent: 10,
                discountSum: 0,
                subtotal: new Pricing(),
                estimatePrice: new Pricing(),
                halfInstallation: 0,
                halfInstallationWithDiscount: 0,
                isHalfInstallation: true,
                totalSquareFt: 0,
            };
            spaces.value = [];
        };

        const renameEstimate = (title = "") => {
            if (!title && !estimate.value.title) {
                estimate.value.title = generateEstimateTitle();
            } else {
                estimate.value.title = title;
            }
        };

        const generateEstimateTitle = () => {
            return [
                estimate.value.client.name,
                estimate.value.installedSystemCode
                    ? getTitleFromDictByCode(
                          dictStore.getSystemTypes(),
                          estimate.value.installedSystemCode,
                      )
                    : "",
                estimate.value.shippingZip,
                estimate.value.shippingState,
            ]
                .filter((item) => (item ? item.trim() : false))
                .join(", ");
        };

        const load = async (estimateCode: string, force = false) => {
            // TODO: REFACTOR THIS
            if (force) {
                const estimateData = await getEstimate(estimateCode);
                useDictStore().loadAllDictsByDate(
                    estimateData.estimate?.info?.priceDateFormated,
                );

                estimate.value = estimateData.estimate.info;
                estimate.value.progress = estimateData.estimate.progress;
                estimate.value.progress.isSaved = true;
            } else {
                const storedEstimate = JSON.parse(
                    localStorage.getItem(LOCAL_STORAGE_ESTIMATE_KEY),
                );
                if (
                    storedEstimate &&
                    storedEstimate.info.code === estimateCode
                ) {
                    return;
                }
            }
            // estimate.value.progress. = estimateCode;
        };

        const getActiveEstimateCode = () => {
            return estimate.value.code;
        };

        const getEstimateReadonly = () => {
            const progress = estimate.value.progress;
            const roles = profile.value.roles || [];

            if (!profile.value || !roles.length || !progress) {
                return true;
            }

            if (
                progress.status === "DRAFT" ||
                progress.status === "DONE" ||
                progress.status === "QUOTE"
            ) {
                return false;
            }

            const estimateInfo = estimate.value;
            const estimateStatus = estimate.value?.progress?.status;
            const userEmail = profile.value?.email;

            if (roles.some((role) => role.slug === "admin")) {
                return false;
            }

            // Если пользователь менеджер и совпадает email
            if (roles.some((role) => role.slug === "manager")) {
                if (!estimateInfo?.managerCode || !userEmail) {
                    return true;
                }
                return estimateInfo.managerCode !== userEmail;
            }

            // Если пользователь оценщик и статус ORDER
            if (
                roles.some((role) => role.slug === "estimator") &&
                estimateStatus === "ORDER"
            ) {
                return false;
            }

            return true;
        };

        const save = async (status?: string) => {
            const spaces = computed(() => spacesStore.spaces);
            const delivery = computed(() => deliveryStore.activeDelivery);

            const summary = SummaryService.fromEstimate(
                estimate.value,
                spaces.value,
                delivery.value,
            );

            const isNew = !estimate.value.progress.isSaved;

            estimate.value.progress.status = status || "DRAFT";
            estimate.value.progress.isSaved = true;
            estimate.value.progress.updated = dayjs().unix().toString();

            const savingData = {
                estimate: EstimateConverterService.convertToOldFormat(
                    estimate.value,
                    spaces.value,
                    delivery.value,
                ),
                calculation:
                    EstimateConverterService.convertCalculationToOldFormat(
                        estimate.value,
                        spaces.value,
                    ),
                summary: summary,
                header: EstimateConverterService.convertToOldBackedHeader(
                    estimate.value,
                ),
            };
            try {
                await postEstimate(savingData, estimate.value.code, isNew);
                estimate.value.progress.isSaved = true;
            } catch (error) {
                console.error(error);
                estimate.value.progress.isSaved = !isNew;
            }
        };

        const updateProgressInfo = (data: object): void => {
            console.debug("updateProgressInfo", data);
            estimate.value.progress = { ...estimate.value.progress, ...data };
        };

        const calculateTotalSquareFt = () => {
            if (!activeSpace.value) {
                return;
            }

            const totalSqFt = spaces.value.reduce((total, space) => {
                const spaceTotalSqFt = space
                    .calculateTotalAreaSqFt()
                    .getValue();

                return total + spaceTotalSqFt;
            }, 0);

            estimate.value.totalSquareFt = new DimensionValue(totalSqFt);
        };

        const $restoreEstimate = (estimateInfo: any) => {
            estimate.value = estimateInfo;
        };

        return {
            estimate,
            $reset,
            $restoreEstimate,
            renameEstimate,
            load,
            getActiveEstimateCode,
            getEstimateReadonly,
            save,
            updateProgressInfo,
            calculateTotalSquareFt,
        };
    },
    { persist: true },
);
