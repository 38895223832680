import { IComponentConfig } from "@/app/@types/base-component";
import { ReactiveComponent } from "./base-component";
import { unwrapRef } from "@/utils/reactive";

export class GlassPartition extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "glassPartition" });
    }
}

export class GlassThinknessPartition extends ReactiveComponent {
    constructor(config: IComponentConfig) {
        super({ ...config, type: "glassThinknessPartition" });
    }

    public is3_8Glass() {
        return unwrapRef(this.structureType) === "3-8";
    }
}
